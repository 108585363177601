import { Button, Grid } from '@mui/material';
import { pages } from '@pages/index';
import { getRoles } from '@utils/cognito';
import { useCallback, useState } from 'react';

export const Home = function () {
  return <Grid container>
    <Grid item sm={12}>
      {pages
        .filter(page => !page.hidden)
        .filter(page => !page.requiredRoles || page.requiredRoles?.find(role => getRoles().includes(role)))
        .map(page => <Button key={page.name} fullWidth href={page.path} variant={"contained"}>
        {page.name}
      </Button>)}
    </Grid>
  </Grid>;
}
