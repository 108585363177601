import { Alert, AlertColor, Box, Snackbar } from '@mui/material';
import { postVehiclePassCheckin } from '@utils/api/vehiclePass';
import { VehiclePass } from '@_types/VehiclePass';
import { useCallback, useMemo, useState,useRef } from 'react';
import { QRCodeInfo } from './QRCodeInfo';
import { QrScanner } from '@yudiel/react-qr-scanner';
import 'src/pages/VerifyQRCode/index.css'


type Props = {
  verifyLocation: string
}

export const VerifyQRCode = function ({ verifyLocation }:Props) {
  const [openQRCodeInfo, setopenQRCodeInfo] = useState(false);
  const [resulttext, setResulttext] =useState("");
  const [data, setData] = useState<string|undefined>();
  const [vehiclePass, setVehiclePass] = useState<VehiclePass|null|undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string|undefined>();

  const windowHeight = useRef(window.innerHeight).current-120;
  const windowWidth = useRef(window.innerWidth).current-600;

  
  const [message, setMessage] = useState<{
    type: AlertColor
    content: string
  }|undefined>();
  const [needConfirmation, setNeedConfirmation] = useState(false);

  const status = useMemo(() => {
    if (loading) {
      return "載入中...";
    }
    if (needConfirmation) {
      return "請確認許可證資料";
    }
    return "請掃二維碼";
  }, [loading, needConfirmation]);

  const showGoldenButton = useMemo(() => vehiclePass && error, [vehiclePass, error]);

  const handleConfirm = useCallback((test:boolean, override:boolean = false) => {
    if (vehiclePass && verifyLocation) {
      setLoading(true);
      postVehiclePassCheckin(vehiclePass.id, verifyLocation, test, override)
      .then(() => {
        setMessage({
          type: "success",
          content: `已成功為許可證${vehiclePass.passId}報到`
        });
      })
      .catch((error) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
        setVehiclePass(undefined);
        setNeedConfirmation(false);
        setopenQRCodeInfo(false);
      });
    }
  }, [vehiclePass, verifyLocation]);

  const handleresult = (result:any)=>{
    if (result && !openQRCodeInfo &&!needConfirmation){
      setResulttext(result)
      setopenQRCodeInfo(true)
      setLoading(true);
      setError(undefined);

      postVehiclePassCheckin(result, verifyLocation, true, false)
      .then((response) => {
        setVehiclePass(response);
        setNeedConfirmation(response.needConfirmation);
        
      })
      .catch((error) => {
        //console.log("vehiclePass", error.response.data);
        if (error.response.data.type === "VehiclePass") {
          setVehiclePass(error.response.data);
          setError(error.response.data.error);
        } else {
          setError(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
        
        setData(undefined);
      });
    }
    }
   



  return <>
    {verifyLocation && <>
    <Box className='box-temp'  > 
      <QrScanner
      
          onDecode={(result) => handleresult(result)}
          onError={(error) => {
            //console.log(error?.message)
          }}
          containerStyle={{height:windowHeight,width:windowWidth}}
      />
  </Box>
    
    </>}
   <QRCodeInfo open={openQRCodeInfo} setOpen={setopenQRCodeInfo} 
    vehiclePass={vehiclePass} error={error} goldenButton={showGoldenButton} needConfirmation={needConfirmation} 
    setVehiclePass={setVehiclePass} setNeedConfirmation={setNeedConfirmation}
    handleConfirm={handleConfirm} loading={loading} verifyLocation={verifyLocation}
    /> 
    <Snackbar open={!!message} autoHideDuration={5000} onClose={() => setMessage(undefined)}>
      <Alert onClose={() => setMessage(undefined)} severity={message?.type} sx={{ width: '100%' }}>{message?.content}</Alert>
    </Snackbar>
  </>
}
