import { Button, Drawer, Stack } from "@mui/material";
import { pages } from "@pages/index";
import { getRoles } from "@utils/cognito";
import React from "react";

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function AppDrawer({open, setOpen}: Props) {
  return <Drawer
    anchor={"left"}
    open={open}
    onClose={() => setOpen(false)}
  >
    <Stack direction={"column"} spacing={2}>
      {pages
      .filter(page => !page.hidden)
      .filter(page => !page.requiredRoles || page.requiredRoles?.find(role => getRoles().includes(role)))
      .map(page => <Button
        key={page.path}
        variant={"text"}
        href={page.path}
        onClick={() => setOpen(false)}>
          {page.name}
      </Button>)}
    </Stack>
  </Drawer>
}