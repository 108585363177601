import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { Components, createTheme } from '@mui/material/styles';
import React from 'react';

export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained"
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "contained"
      }
    },
    MuiCircularProgress: {
      defaultProps: {
        style: {
          textAlign: "center"
        }
      }
    }
  } as Components
});