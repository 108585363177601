import { Alert, AlertColor, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { postBroadcastMessage } from '@utils/api/notify';
import { fetchCannedMessages } from '@utils/api/cannedMessage';
import { useCallback, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { isAxiosError } from 'axios';

export const BroadcastMessage = function () {
  const [cannedMessages, setCannedMessages] = useState<string[]>([]);
  const [message, setMessage] = useState("");
  const [inapp, setInapp] = useState(true);
  const [sms, setSms] = useState(false);
  const [sending, setSending] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState<{
    type: AlertColor
    content: string
  }|undefined>();

  const handleSubmit = useCallback(() => {
    if (window.confirm("確定要廣播訊息？")) {
      setSending(true);
      postBroadcastMessage(message, undefined, inapp, sms?"all":"none")
      .then((response) => {
        const messages = [];

        if (response.inapp) {
          messages.push(`In-App裝置數: ${response.inapp.deviceCount}`);
        }
        if (response.sms) {
          messages.push(`SMS成功數: ${response.sms.success}`);
          messages.push(`SMS失敗數: ${response.sms.fail}`);
        }
        setSnackbarMessage({
          type: "success",
          content: messages.join(", ")
        });
      })
      .catch((error) => {
        let errorContent = error.message;
        if (isAxiosError(error)) {
          errorContent = error.response?.data?.message || errorContent;
        }
        setSnackbarMessage({
          type: "error",
          content: errorContent
        })
      })
      .finally(() => {
        setSending(false);
      })
    }
  }, [message, inapp, sms]);

  useEffect(() => {
    fetchCannedMessages().then(response => setCannedMessages(response.map(x => x.content)))
  }, []);

  return <>
    <Typography variant={"h4"}>廣播訊息</Typography>
    <Typography>請注意，訊息會發放至所有司機</Typography>
    <Stack spacing={2}>
      <FormControl fullWidth>
        <InputLabel>預設訊息</InputLabel>
        <Select
          onChange={(e) => setMessage(e.target.value as string)}
        >
          {cannedMessages.map(cannedMessage => <MenuItem
            key={cannedMessage}
            value={cannedMessage}
          >{cannedMessage}</MenuItem>)}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label={"訊息內容"}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <Stack direction="row">
        <FormControlLabel style={{ width: "100%" }} checked={inapp} control={<Checkbox onChange={(e) => setInapp(e.target.checked)}/>} label="In-App 通知" />
        <FormControlLabel style={{ width: "100%" }} checked={sms} control={<Checkbox onChange={(e) => setSms(e.target.checked)}/>} label="SMS 通知" />
        {/* <Select fullWidth value={sms} onChange={(e) => setSms(e.target.value)} placeholder="發送SMS模式" size="small">
          <MenuItem value="all">發送SMS至所有司機</MenuItem>
          <MenuItem value="failover">發送SMS給沒有App的司機</MenuItem>
          <MenuItem value="none">不發送SMS</MenuItem>
        </Select> */}
      </Stack>

      <LoadingButton fullWidth disabled={!inapp && !sms} loading={sending} onClick={handleSubmit}>發送</LoadingButton>
    </Stack>

    <Snackbar open={!!message} autoHideDuration={5000} onClose={() => setSnackbarMessage(undefined)}>
      <Alert onClose={() => setSnackbarMessage(undefined)} severity={snackbarMessage?.type} sx={{ width: '100%' }}>{snackbarMessage?.content}</Alert>
    </Snackbar>
  </>;
}
