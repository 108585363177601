import { cognitoLogout } from '@utils/cognito';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRBACContext } from 'react-simple-rbac';

export const Logout = function () {  
  const rbac = useRBACContext();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Logout Page");
    cognitoLogout(() => {
      try {
        rbac.resetAll();
        console.log("RBAC resetted");
      } catch (error) {
        console.error(error);
      }
      navigate("/login", { replace: true });
    });
  }, [rbac, navigate]);

  return <></>
}
