import { Vehicle, VehicleFilter } from "@_types/Vehicle";
import { Paginated, PaginationParams, api } from ".";

export async function fetchVehicles(params: VehicleFilter, pagination?: PaginationParams) {
  const response = await api.get<Paginated<Vehicle>>("/vehicle", { params: {...params, ...pagination} });

  return response.data;
}

export async function postVehicle(id:string, params: { blacklisted: boolean }) {
  const response = await api.post<Vehicle>("/vehicle", {id, ...params});

  return response.data;
}