import { fetchEventReportEventList, fetchFinishedCount } from "@utils/api/report";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DataGrid } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import { DownloadReportButton } from "./DownloadReportButton";

type Row = {
  id: string
  name: string
  startDate: string
  endDate: string
  reportGenDate?: string
}

export function ReportsEventReport() {
  const [searchParams, setSearchParams] = useSearchParams();

  const levels = useMemo(() => ["L1","L2","L3","L4","L5"], []);
  const phases = useMemo(() => ["1","2"], []);

  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState<moment.Moment|null>(searchParams.get("start") ? moment(searchParams.get("start")) : null);
  const [end, setEnd] = useState<moment.Moment|null>(searchParams.get("end") ? moment(searchParams.get("end")) : null);
  const [level, setLevel] = useState<string|undefined>(searchParams.get("level") || undefined);
  const [phase, setPhase] = useState<string|undefined>(searchParams.get("phase") || undefined);
  const [q, setQ] = useState<string|undefined>(searchParams.get("q") || undefined);
  const [data, setData] = useState<Row[]>([]);

  const handleLoad = useCallback(() => {
    const q = searchParams.get("q") || undefined;
    const start = searchParams.get("start") || undefined;
    const end = searchParams.get("end") || undefined;
    const level = searchParams.get("level") || undefined;
    const phase = searchParams.get("phase") || undefined;

    setLoading(true);
    fetchEventReportEventList({ q, start, end, level, phase })
    .then(response => {
      setData(response);
    })
    .finally(() => {
      setLoading(false);
    })
  }, [searchParams]);

  const handleSearch = useCallback(() => {
    setSearchParams({
      ...(start && { start: start.format("YYYY-MM-DD") }),
      ...(end && { start: end.format("YYYY-MM-DD") }),
      ...(level && { level }),
      ...(phase && { phase }),
      ...(q && { q })
    });
  }, [start, end, level, phase, q]);

  useEffect(handleLoad, [handleLoad]);

  return <Box style={{ marginTop: 10 }}>
    <TextField fullWidth value={q} onChange={(e) => setQ(e.target.value)} label="搜尋"/>
    <Stack direction="row">
      <FormControl fullWidth>
        <InputLabel>期數</InputLabel>
        <Select value={phase} onChange={(e) => setPhase(e.target.value)}>
          <MenuItem value="">所有</MenuItem>
          {phases.map(phase => <MenuItem key={phase} value={phase}>{phase}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>樓層</InputLabel>
        <Select fullWidth value={level} onChange={(e) => setLevel(e.target.value)} placeholder="樓層">
          <MenuItem value="">所有</MenuItem>
          {levels.map(level => <MenuItem key={level} value={level}>{level}</MenuItem>)}
        </Select>
      </FormControl>
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true
          }
        }}
        label="開始日期"
        format="YYYY-MM-DD"
        maxDate={end || undefined}
        value={start}
        onChange={setStart}
      />
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true
          }
        }}
        label="結束日期"
        format="YYYY-MM-DD"
        minDate={start || undefined}
        value={end}
        onChange={setEnd}
      />
    </Stack>
    <Button fullWidth onClick={handleSearch}>搜尋</Button>
    <Box style={{ height: "70vh"}}>
      <DataGrid
        rows={data}
        loading={loading}
        disableColumnMenu
        columns={[
          { field: "id", headerName: "編號" },
          { field: "name", headerName: "活動名稱", width: 700 },
          { field: "startDate", headerName: "開始日期" },
          { field: "endDate", headerName: "結束日期" },
          { field: "reportGenDate", headerName: "上次報告輸出日期", width: 200, valueFormatter: ({value}) => value && moment(value).format("YYYY-MM-DD HH:mm:ss"), },
          { field: "downloadReport", headerName: "下載報告", renderCell: ({ row }) => <DownloadReportButton id={row.id}/>}
        ]}
        rowHeight={40}
      />
    </Box>
  </Box>
}