import { DashboardNotification } from "@_types/DashboardNotification";
import { api } from ".";

export async function getDashboardNotifications(type:string, hideAcked:boolean, eventSummaryPhase: string) {
  const { data } = await api.get<DashboardNotification[]>("/dashboardNotification", { params: { type, hideAcked, eventSummaryPhase }});

  return data;
}

export async function postDashboardNotificationAck(id:string) {
  const { data } = await api.post("/dashboardNotification/ack", { id });

  return data;
}