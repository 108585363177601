import { AppBar as MuiAppBar, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import { AppDrawer } from "@components/AppBar/AppDrawer";
import { cognitoRefreshSession, cognitoUserSession } from "@utils/cognito";
import { useNavigate } from "react-router-dom";
import { isDev } from "@utils/isDev";

export function AppBar() {
  const navigate = useNavigate();

  const [appDrawerOpen, setAppDrawerOpen] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      const expiration = cognitoUserSession?.getAccessToken().getExpiration();
      // console.log("expiration", expiration && expiration*1000, "current", new Date().getTime());
      if (expiration && ((expiration*1000) < new Date().getTime())) {
        cognitoRefreshSession().then(accessToken => {
          if (!accessToken) {
            navigate("/login", { replace: true });
          }
        })
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    }
  }, [navigate]);

  return <>
    <MuiAppBar position="sticky" color={isDev() ? "secondary" : undefined}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setAppDrawerOpen((value) => !value)}
        >
          <MenuIcon />
        </IconButton>
        <Typography>會展快運易控制台{isDev() && " (Development)"}</Typography>
      </Toolbar>
    </MuiAppBar>
    <AppDrawer open={appDrawerOpen} setOpen={setAppDrawerOpen}/>
  </>
  
}