import { LoadingButton } from '@mui/lab';
import { Alert, AlertColor, InputAdornment, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { cognitoAuthenticate, cognitoCompleteNewPasswordChallenge, cognitoSendCustomChallengeAnswer } from '@utils/cognito';
import { isDev } from '@utils/isDev';
import { IAuthenticationCallback } from 'amazon-cognito-identity-js';
import React, { KeyboardEventHandler, useCallback, useState } from 'react';
import { useRBACContext } from 'react-simple-rbac';

export const Login = function () {
  const rbac = useRBACContext();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [challengeParameters, setChallengeParameters] = useState<{correlation:string} | undefined>();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{
    type: AlertColor
    content: string
  }|undefined>();
  
  const handleLogin = useCallback(() => {
    setLoading(true);

    const callbacks:IAuthenticationCallback = {
      onSuccess: (session) => {
        setLoading(false);
        rbac.addRoles(session.getAccessToken().payload["cognito:groups"]);
        window.location.href = "/";
      },
      onFailure: (error) => {
        setLoading(false);
        console.error(error);
        setMessage({
          type: "error",
          content: error.message
        });
      },
      customChallenge: (data) => {
        setLoading(false);
        console.log("ChallengeParameters", data)
        setChallengeParameters(data);
      },
      newPasswordRequired: (userAttributes) => {
        cognitoCompleteNewPasswordChallenge(password, userAttributes, {
          onSuccess: (session) => {
            setLoading(false);
            rbac.addRoles(session.getAccessToken().payload["cognito:groups"]);
            window.location.href = "/";
          },
          onFailure: (error) => {
            setLoading(false);
            console.error(error);
            setMessage({
              type: "error",
              content: error.message
            });
          },
        })
      }
    }

    if (challengeParameters && otp) {
      cognitoSendCustomChallengeAnswer(otp, callbacks);
    } else {
      cognitoAuthenticate(username, password, callbacks);
    }
  }, [username, password, challengeParameters, otp, rbac]);

  const handleEnterKey:KeyboardEventHandler = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin();
    }
  }, [handleLogin]);

  return <>
    <Typography variant={"h3"} style={{ textAlign: "center", marginBottom: "0.5em" }}>會展快運易控制台{isDev() && " (Development)"}</Typography>
    <Stack spacing={2}>
      <TextField fullWidth value={username} onChange={e => setUsername(e.target.value)} onKeyDown={handleEnterKey} label={"用戶名稱"} />
      <TextField fullWidth value={password} onChange={e => setPassword(e.target.value)} onKeyDown={handleEnterKey} label={"密碼"} type={"password"}/>
      {!!challengeParameters && <TextField fullWidth value={otp} onChange={e => setOtp(e.target.value)} onKeyDown={handleEnterKey} label={"驗證碼"} InputProps={{ startAdornment:<InputAdornment position="start">{challengeParameters.correlation}-</InputAdornment>}} inputProps={{ inputMode: 'numeric', pattern: '[0-9]{0,6}' }}/>}
      <LoadingButton fullWidth variant={"contained"} loading={loading} onClick={handleLogin}>登入</LoadingButton>
    </Stack>
    <Snackbar open={!!message} autoHideDuration={5000} onClose={() => setMessage(undefined)}>
      <Alert onClose={() => setMessage(undefined)} severity={message?.type} sx={{ width: '100%' }}>{message?.content}</Alert>
    </Snackbar>
  </>
}
