import axios from "axios";
import { api } from "./api";

export async function downloadReport(url:string, filename:string) {
  const s3Url = (await api.get(url)).data.url;

  const blob:Blob = (await axios.get(s3Url, { responseType: "blob" })).data;

  saveOrOpenBlob(blob, filename);
}

function saveOrOpenBlob(blob:Blob, filename:string) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a"); // Or maybe get it from the current document
  link.href = url;
  link.download = filename;
  link.click();
}
