import { SendMessageDialog } from "@components/SendMessageDialog";
import { Box, Button, Stack, Typography } from "@mui/material";
import { postVehiclePassRelease } from "@utils/api/vehiclePass";
import { VehiclePass } from "@_types/VehiclePass";
import _ from "lodash";
import React, { useCallback, useState } from "react";

type Props = {
  selectedVehiclePasses: VehiclePass[]
  setSelectedVehiclePasses: React.Dispatch<React.SetStateAction<VehiclePass[]>>
  handleReload: () => any
}

export function MultipleSelectToolbar({ selectedVehiclePasses, setSelectedVehiclePasses, handleReload }: Props) {
  const [openSendMessageDialog, setOpenSendMessageDialog] = useState(false);
  
  const handleRelease = useCallback(() => {
    if (window.confirm(`確定將${selectedVehiclePasses.length}張許可證放行往葵涌/會展？`)) {
      Promise.allSettled(selectedVehiclePasses.map(vehiclePass => postVehiclePassRelease(vehiclePass.id))).then(response => {
        alert(`成功將${response.filter(x => x.status==="fulfilled").length}張許可證放行往葵涌/會展`);
        handleReload()
      });
    }
  }, [selectedVehiclePasses, handleReload]);

  const handleSendMessage = useCallback(() => {
    setOpenSendMessageDialog(true);
  }, []);

  const handleCancelSelection = useCallback(() => {
    setSelectedVehiclePasses([]);
  }, [setSelectedVehiclePasses]);
  
  return <Box style={{ position: "sticky", bottom: 0, backgroundColor: "white", width: "100%", boxShadow: "0 -5px 5px grey", paddingBottom: "1em" }}>
    <Typography style={{ textAlign: "right", marginTop: 10 }}>已選擇 {selectedVehiclePasses.length} 筆記錄</Typography>
    <Stack direction={"row"}>
      <Button fullWidth color={"success"} onClick={handleRelease}>去葵涌/會展</Button>
      <Button fullWidth onClick={handleSendMessage}>發送訊息</Button>
      <Button fullWidth color={"error"} onClick={handleCancelSelection}>取消選取</Button>
    </Stack>
    <SendMessageDialog open={openSendMessageDialog} handleClose={() => setOpenSendMessageDialog(false)} driverPhones={_.chain(selectedVehiclePasses).map("driverPhone").compact().uniq().value()}/>
  </Box>
}