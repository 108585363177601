import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession, IAuthenticationCallback } from "amazon-cognito-identity-js";

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || ""
});

export let cognitoUser:CognitoUser|null = null;
export let cognitoUserSession:CognitoUserSession|null = null;

export function cognitoAuthenticate(username:string, password:string, callbacks: IAuthenticationCallback) {
  cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool
  });
  
  cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
  cognitoUser.authenticateUser(new AuthenticationDetails({
    Username: username,
    Password: password
  }), callbacks);
}

export function cognitoSendCustomChallengeAnswer(token:string, callbacks: IAuthenticationCallback) {
  if (cognitoUser) {
    cognitoUser.sendCustomChallengeAnswer(token, callbacks);
  }
}

export const loadSignInUserSession = () => new Promise<{user: CognitoUser|null, session: CognitoUserSession|null}>((resolve, reject) => {
  // console.log("[loadSignInUserSession]", "getting user");
  const user = userPool.getCurrentUser();
  if (user) {
    // console.log("[loadSignInUserSession]", "getting session");
    user.getSession(function (error:any, session:CognitoUserSession) {
      if (error) {
        reject(error);
      } else {
        cognitoUserSession = session;
        // console.log("[loadSignInUserSession]", "user and session ready");
        resolve({ user, session });
      }
    });
  } else {
    resolve({ user, session: null });
  }
});

export function getRoles() {
  if (!cognitoUserSession) {
    return [];
  }
  const roles:string[] = cognitoUserSession?.getAccessToken().payload?.["cognito:groups"] || [];
  return roles;
}

export async function cognitoRefreshSession() {
  return new Promise<string|null>((resolve) => {
    userPool.getCurrentUser()?.getSession((error:Error, session:CognitoUserSession|null) => {
      if (error) {
        console.error(error);
        resolve(null);
      } else {
        const refreshToken = session?.getRefreshToken();
          if (refreshToken) {
            userPool.getCurrentUser()?.refreshSession(refreshToken, (error, session:CognitoUserSession) => {
            if (error) {
              console.error(error);
              resolve(null);
            }
            cognitoUserSession = session;
            const accessToken = session.getIdToken().getJwtToken();
            resolve(accessToken);
          });
        } else {
          console.error("[cognitoRefreshSession]", "no refresh token");
          resolve(null);
        }
      }
    });
  });
}

export function cognitoLogout(callback?:() => void) {
  cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut(() => {
      console.log("sign out success");
      cognitoUser = null;
      callback && callback();
    });
  }
}

export function cognitoCompleteNewPasswordChallenge(password:string, userAttributes:any, callbacks:IAuthenticationCallback) {
  if (cognitoUser) {
    delete userAttributes.email_verified;

    console.log(userAttributes);
  
    cognitoUser.completeNewPasswordChallenge(password, userAttributes, callbacks);  
  }
}