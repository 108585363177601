import { useCallback, useMemo, useState } from "react";
import { Setting as SettingType } from "@_types/Setting";
import { Button, Checkbox, IconButton, Stack, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from "@mui/lab";
import { fetchSetting, postSetting } from "@utils/api/setting";
import { toast } from "react-toastify";

type Props = {
  setting: SettingType
}

export function SettingValue({ setting }:Props) {
  const [sending, setSending] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(setting.value);

  const handleSave = useCallback(() => {
    setSending(true);

    postSetting(setting.id, value)
    .then(() => fetchSetting(setting.id).then(data => setValue(data.value)))
    .then(() => {
      toast.success("設定已儲存");
      setEditMode(false);
    })
    .finally(() => setSending(false));
  }, [setting, value]);

  const disableControl = useMemo(() => !editMode || sending, [editMode, sending]);

  const renderValue = useMemo(() => {
    if (setting.type === "boolean") {
      return <Checkbox disabled={disableControl} size="small" checked={value === "true"} onChange={(e) => setValue(`${e.target.checked}`)}/>
    }
  }, [value, disableControl]);

  const renderButton = useMemo(() => {
    if (editMode) {
      return <>
        <LoadingButton loading={sending} color="success" onClick={handleSave} size="small"><SaveIcon/></LoadingButton>
        <Button color="error" onClick={() => setEditMode(false)}><CancelIcon/></Button>
      </>
    } else {
      return <Button onClick={() => setEditMode(true)} size="small"><EditIcon/></Button>
    }
  }, [editMode, sending, handleSave]);

  return <Stack direction="row">
    {renderValue}
    {renderButton}
  </Stack>
}