import { Box, Grid, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from '@mui/lab';
import './Filter.css';

type Props = {
  date: moment.Moment | null
  setDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>
  phase: string
  setPhase: React.Dispatch<React.SetStateAction<string>>
  lastUpdate?: string
  handleSearch: () => any
  loading: boolean
}

export function Filter({ date, setDate, phase, setPhase, lastUpdate, handleSearch, loading }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const phases = useMemo(() => ["1", "2"], []);

  const handleSearchDate = useCallback(() => {
    if (date?.isValid()) {
      setSearchParams(() => {
        searchParams.set("date", date.format("YYYY-MM-DD"));
        return searchParams;
      });
    }
  }, [date, searchParams, setSearchParams]);

  const handleChangePhase = useCallback((phase:string) => {
    setSearchParams(() => {
      searchParams.set("phase", phase);
      return searchParams;
    });
    
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch])




  return (
  
    <Box className="big-container-margin">
    <Grid container>
      <Grid item sm={10}>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              size: "small"
            }
          }}
          label="日期"
          value={date}
          onChange={setDate}
          className='date-picker'
          format="DD/MM/YYYY"
        />
      </Grid>
      
      <Grid item sm={2} className="search-container" >      
        <LoadingButton fullWidth variant={"contained"} loading={loading} onClick={handleSearchDate} className="search-date">
          <SearchIcon style={{height:"6em", width:"2em"}}/>
        </LoadingButton>
      </Grid>
    </Grid>

    <Typography className="update-text">最後更新時間：{lastUpdate}</Typography>

    <Stack direction={"row"} className="stack-container"> 
   
      {phases.map(p => <LoadingButton
        loading={loading}
        fullWidth
        key={p}
        onClick={() => handleChangePhase(p)}
        style={{ border: 0, boxShadow: 'none' }}
        sx={phase === p? {backgroundColor: '#1976d2', color:'white'} : {backgroundColor: 'white', color: '#8b8a8a'}}
        >
          {p}期
        </LoadingButton>)}
      
    </Stack>
    
  </Box>

  
  )
}