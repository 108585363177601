import { Driver, DriverFilter } from "@_types/Driver";
import { Paginated, PaginationParams, api } from ".";

export async function fetchDrivers(params: DriverFilter, pagination?: PaginationParams) {
  const response = await api.get<Paginated<Driver>>("/driver", { params: { ...params, ...pagination } });

  return response.data;
}

export async function postDriver(phone:string, blacklisted: boolean) {
  const response = await api.post<Driver>("/driver", { phone, blacklisted });

  return response.data;
}