import { Box, CircularProgress, Grid, Pagination, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Filter } from './Filter';
import { fetchDrivers } from '@utils/api/driver';
import { DriverCard } from './DriverCard';
import { Driver } from '@_types/Driver';
import { useSearchParams } from 'react-router-dom';
import { MultipleSelectToolbar } from './MultipleSelectToolbar';

export function DriverList() {
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [selectedDrivers, setSelectedDrivers] = useState<Driver[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [count, setCount] = useState<number | undefined>(undefined);

  const handleSearch = useCallback(() => {
    setLoading(true);
    fetchDrivers({
      q: searchParams.get("q") || "",
      blacklisted: searchParams.get("blacklisted") || ""
    }, { page, size })
      .then(response => {
        setCount(response.count);
        setDrivers(response.rows);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, page, size]);

  const handleSelected = useCallback((driver: Driver) => {
    if (selectedDrivers.find(x => x.phone === driver.phone)) {
      setSelectedDrivers(old => old.filter(x => x.phone !== driver.phone));
    } else {
      setSelectedDrivers(old => [...old, driver]);
    }
  }, [selectedDrivers]);

  useEffect(handleSearch, [handleSearch]);

  useEffect(() => {
    setPage(1);
  }, [searchParams]);

  return <>
    <Typography variant={"h4"}>司機列表</Typography>
    <Grid container>
      <Grid item sm={12}>
        <Filter handleSearch={handleSearch} />

        {(count !== undefined) && <Box style={{ marginTop: 10, width: "100%", alignItems: "center" }}>
          <Pagination
            page={page}
            count={Math.ceil((count || 0) / size)}
            onChange={(_, x) => setPage(x)}
          />
          <Typography style={{ textAlign: "right", marginTop: 10 }}>共找到 {count} 筆記錄</Typography>
        </Box>}

        {loading && <Box style={{ margin: 10, textAlign: "center", width: "100%" }}>
          <CircularProgress />
        </Box>}

        {!loading && <>
          {drivers.map(driver => <DriverCard key={driver.phone} driver={driver} selectedDrivers={selectedDrivers} handleSelected={() => handleSelected(driver)} handleReload={handleSearch} />)}
        </>}
      </Grid>
      {/* <SpacesAvailable/> */}
    </Grid>
    {!!selectedDrivers.length && <MultipleSelectToolbar selectedDrivers={selectedDrivers} setSelectedDrivers={setSelectedDrivers} handleReload={handleSearch} />}
  </>;
}
