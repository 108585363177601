import { api } from ".";

export async function fetchReport(id:string, filter:{[key:string]:any}) {
  const response = await api.get<any[]>("/report/data", { params: { id, ...filter }});

  return response.data;
}

export async function fetchFinishedCount(start:string, end:string) {
  const response = await api.get<{date:string, count:number}[]>("/report/finishedCount", { params: { start, end }});

  return response.data;
}

export async function fetchEventReportEventList(params: Partial<{ q:string, start:string, end:string, level:string, phase:string }>) {
  const response = await api.get<{id:string, name:string, startDate:string, endDate:string, reportGenDate:string}[]>("/report/eventReport/searchEvent", { params });

  return response.data;
}

export async function fetchEventReport(id:string) {
  const response = await api.get<{url:string}>("/report/eventReport", { params: { id }});

  return response.data;
}