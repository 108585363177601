import { ImportTask } from "@_types/ImportTask"
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { importTaskGet } from "@utils/api/importTask"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

type Props = {
  open: boolean
  onClose: () => any
}

export function ImportTaskDetails({ open, onClose }: Props) {
  const [searchParams] = useSearchParams();

  const [importTask, setImportTask] = useState<ImportTask|undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const id = useMemo(() => {
    return searchParams.get("id");
  }, [searchParams]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      importTaskGet(id).then(data => {
        setImportTask(data);
      })
      .finally(() => {
        setLoading(false);
      });
    } else {
      setImportTask(undefined);
    }
  }, [id]);
  
  return <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
    <DialogTitle>
      <Typography variant="h5">匯入工作錯誤</Typography>
    </DialogTitle>
    <DialogContent>
      {(!importTask || loading)?<Box style={{ alignItems: "center" }}><CircularProgress/></Box>:<>
        {importTask.error ? <Table>
          <TableHead>
            <TableRow>
              <TableCell>檔案名稱</TableCell>
              <TableCell>類別</TableCell>
              <TableCell>錯誤名稱</TableCell>
              <TableCell>原因</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {importTask.error?.map((row) => {
              return <TableRow>
                <TableCell>{row.filename}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.message}</TableCell>
                <TableCell>{JSON.stringify(row.cause)}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table> : <>
          <Typography>匯入工作沒有錯誤</Typography>
        </>}
      </>}
    </DialogContent>
  </Dialog>
}