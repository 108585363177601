import { SendMessageDialog } from "@components/SendMessageDialog";
import { Alert, AlertColor, Box, Button, Card, Checkbox, FormControlLabel, FormGroup, Snackbar, Stack, Typography } from "@mui/material";
import { deleteVehiclePass, postVehiclePassRelease } from "@utils/api/vehiclePass";
import { vehiclePassColour } from "@utils/vehiclePassColour";
import { VehiclePass } from "@_types/VehiclePass";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useHasRoles } from "react-simple-rbac";
import { getRoles } from "@utils/cognito";

type Props = {
  vehiclePass: VehiclePass,
  selectedVehiclePasses: VehiclePass[],
  setSelectedVehiclePasses: React.Dispatch<React.SetStateAction<VehiclePass[]>>
  handleReload: () => any
}

export function VehiclePassCard({ vehiclePass, selectedVehiclePasses, setSelectedVehiclePasses, handleReload }:Props) {
  const hasRoles = useHasRoles(["admin"], true);
  const [message, setMessage] = useState<{
    type: AlertColor
    content: string
  }|undefined>();
  
  const [openSendMessageDialog, setOpenSendMessageDialog] = useState(false);

  const destination = useMemo(() => {
    if (vehiclePass.mode === "TY") {
      return "葵涌";
    } else if (["GF", "FF", "FFC"].includes(vehiclePass.mode)) {
      return "會展";
    }
    return undefined;
  }, [vehiclePass]);

  const releaseButtonLabel = useMemo(() => {
    if (destination) {
      return `去${destination}`;
    }
  }, [destination]);

  const releaseButtonDisable = useMemo(() => {
    if (getRoles().every(x => !["admin"].includes(x))) {
      return true;
    }
    if (vehiclePass.status === "QUEUED") {
      return false;
    }
    return true;
  }, [vehiclePass]);

  const selected = useMemo(() => {
    return !!selectedVehiclePasses.find(x => x.id === vehiclePass.id)
  }, [vehiclePass, selectedVehiclePasses]);

  const handleDelete = useCallback(() => {
    if (window.confirm(`確定要刪除許可證${vehiclePass.passId}嗎？`)) {
      deleteVehiclePass(vehiclePass.id).then(() => {
        setMessage({
          type: "error",
          content: `已刪除許可證${vehiclePass.passId}`
        });
        handleReload();
      });
    }
  }, [vehiclePass, handleReload]);
  
  const handleChecked = useCallback((e:React.SyntheticEvent, checked: boolean) => {
    if (checked) {
      setSelectedVehiclePasses(value => [...value, vehiclePass]);
    } else {
      setSelectedVehiclePasses(value => value.filter(x => x.id !== vehiclePass.id));
    }
  }, [vehiclePass, setSelectedVehiclePasses]);

  const handleRelease = useCallback(() => {
    if (window.confirm(`確定將許可證${vehiclePass.passId}放行往${destination}？`)) {
      postVehiclePassRelease(vehiclePass.id)
      .then(response => {
        alert(`成功將許可證${vehiclePass.passId}放行往${destination}`);
        handleReload();
      })
      .catch(error => {
        alert(`未能將許可證${vehiclePass.passId}放行往${destination} (${error.response.data.message})`);
      });
    }
  }, [vehiclePass, destination, handleReload]);

  const handleSendMessage = useCallback(() => {
    setOpenSendMessageDialog(true);
  }, []);

  const modeText = useMemo(() => {
    if (vehiclePass.mode === "TY") {
      return "葵涌打卡";
    } else if (vehiclePass.mode === "GF") {
      return "場外自行打卡";
    } else if (vehiclePass.mode === "FF") {
      return "直接前往會展";
    } else if (vehiclePass.mode === "FFC") {
      return "直接前往會展（可叫號）";
    }
  }, [vehiclePass]);

  return <>
    <Card variant={"outlined"} style={{ marginTop: 5, marginBottom: 5, backgroundColor: selected ? "#ccffcc" : undefined, borderColor: "black" }}>
      <Box style={{ paddingTop: 5, paddingLeft: 10, paddingBottom: 5, backgroundColor: vehiclePassColour(vehiclePass.passId) }}>
        <FormGroup>
          <FormControlLabel
            style={{ fontWeight: "bold", fontSize: "large" }}
            control={<Checkbox checked={selected} />}
            label={`${vehiclePass.passId}${vehiclePass.queuePos ? ` (籌號: ${vehiclePass.queuePos})` : ""}`}
            onChange={handleChecked}
          />
        </FormGroup>
      </Box>
      <Box style={{ padding: 10 }}>
        <Stack direction={"row"} style={{ marginBottom: 5 }}>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>活動</Typography>
            <Typography>{vehiclePass.event ? `(${vehiclePass.event.id}) ${vehiclePass.event.name}` : "-"}</Typography>
          </Box>

          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>期數</Typography>
            <Typography>{vehiclePass.phase || "-"}</Typography>
          </Box>
        </Stack>

        <Stack direction={"row"} style={{ marginBottom: 5 }}>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>車牌</Typography>
            <Typography>{vehiclePass.license || "-"}</Typography>
          </Box>

          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>司機電話</Typography>
            <Typography>{vehiclePass.driverPhone || "-"}</Typography>
          </Box>
        </Stack>

        <Stack direction={"row"} style={{ marginBottom: 5 }}>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>司機已選時段</Typography>
            <Typography>{vehiclePass.selectedTimeslot? `${moment(vehiclePass.selectedTimeslot.startTime).format("DD/MM HH:mm")} - ${moment(vehiclePass.selectedTimeslot.endTime).format("DD/MM HH:mm")}` : "-"}</Typography>
          </Box>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>樓層</Typography>
            <Typography>{vehiclePass.level || "-"}</Typography>
          </Box>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>場地</Typography>
            <Typography>{vehiclePass.venue || "-"}</Typography>
          </Box>
        </Stack>

        <Box style={{ marginBottom: 5 }}>
          <Typography style={{ fontSize: "small" }}>備註</Typography>
          <Typography>{vehiclePass.remarks || "-"}</Typography>
        </Box>

        <Stack direction={"row"} style={{ marginBottom: 5 }}>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>是否允許使用一期貨車升降機？</Typography>
            <Typography>{vehiclePass.enableLift?"✓":"-"}</Typography>
          </Box>

          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>報到永不逾時</Typography>
            <Typography>{vehiclePass.alwaysEnable?"✓":"-"}</Typography>
          </Box>

          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>報到模式</Typography>
            <Typography>{modeText}</Typography>
          </Box>
        </Stack>

        <Stack direction={"row"} style={{ marginBottom: 5 }}>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>司機登記許可證時間</Typography>
            <Typography>{(vehiclePass.bindTime && moment(vehiclePass.bindTime).format("YYYY-MM-DD HH:mm:ss")) || "-"}</Typography>
          </Box>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>取籌時間</Typography>
            <Typography>{(vehiclePass.queueTime && moment(vehiclePass.queueTime).format("YYYY-MM-DD HH:mm:ss")) || "-"}</Typography>
          </Box>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>放行時間</Typography>
            <Typography>{(vehiclePass.releaseTime && moment(vehiclePass.releaseTime).format("YYYY-MM-DD HH:mm:ss")) || "-"}</Typography>
          </Box>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>打卡時間</Typography>
            <Typography>{(vehiclePass.checkinTime && moment(vehiclePass.checkinTime).format("YYYY-MM-DD HH:mm:ss")) || "-"}</Typography>
          </Box>
          <Box style={{ width: "100%" }}>
            <Typography style={{ fontSize: "small" }}>會展報到時間</Typography>
            <Typography>{(vehiclePass.finishTime && moment(vehiclePass.finishTime).format("YYYY-MM-DD HH:mm:ss")) || "-"}</Typography>
          </Box>
        </Stack>
      </Box>
      <Box style={{ backgroundColor: "white" }}>
        {hasRoles && <Stack direction={"row"} spacing={1}>
          <Button fullWidth color={"warning"} href={`/addModifyVehiclePass?id=${vehiclePass.id}`}>更改許可證</Button>
          <Button fullWidth color={"error"} onClick={handleDelete}>刪除許可證</Button>
        </Stack>}
        <Stack direction={"row"} spacing={1}>
          <Button fullWidth disabled={releaseButtonDisable} color={"success"} onClick={handleRelease}>{releaseButtonLabel}</Button>
          <Button fullWidth disabled={!vehiclePass.driverPhone} onClick={handleSendMessage}>發送訊息</Button>
        </Stack>
        {vehiclePass.driverPhone && <SendMessageDialog open={openSendMessageDialog} handleClose={() => setOpenSendMessageDialog(false)} driverPhones={[vehiclePass.driverPhone]}/>}
      </Box>
    </Card>
  
    <Snackbar open={!!message} autoHideDuration={5000} onClose={() => setMessage(undefined)}>
      <Alert onClose={() => setMessage(undefined)} severity={message?.type} sx={{ width: '100%' }}>{message?.content}</Alert>
    </Snackbar>
  </>
}