import { Box, Typography } from "@mui/material";
import { fetchEventSummary } from "@utils/api/eventSummary";
import { SummaryEvent } from "@_types/Event";
import { Queue } from "@_types/Queue";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BatchChangeCheckinModeDialog } from "./BatchChangeCheckinModeDialog";
import { BatchReleaseDialog } from "./BatchReleaseDialog";
import { EventCard } from "./EventCard";
import { Filter } from "./Filter";
import './index.css';
import { NotificationCentre } from "./NotificationCentre";

export function Call() {
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<moment.Moment|null>(moment());
  const [phase, setPhase] = useState<string>("1");
  const [lastUpdate, setLastUpdate] = useState<string|undefined>();
  const [queues, setQueues] = useState<Queue[]>([]);
  const [events, setEvents] = useState<SummaryEvent[]>([]);
  const [selectedQueue, setSelectedQueue] = useState<Queue|undefined>();
  const [selectedEvent, setSelectedEvent] = useState<SummaryEvent|undefined>();
  const [openBatchReleaseDialog, setOpenBatchReleaseDialog] = useState(false);
  const [openBatchChangeCheckinModeDialog, setOpenBatchChangeCheckinModeDialog] = useState(false);

  const levels = useMemo(() => ["L1","L2","L3","L4","L5"], []);

  const handleSearch = useCallback(() => {
    const dateString = searchParams.get("date") || moment().format("YYYY-MM-DD");
    const phaseString = searchParams.get("phase") || "1";

    setLoading(true);
    fetchEventSummary(dateString, phaseString).then((result) => {
      setLastUpdate(moment().format("YYYY-MM-DD HH:mm:ss"));
      setEvents(result.events);
      setQueues(result.queues);
    }).finally(() => {
      setLoading(false);
    });
  }, [searchParams]);

  useEffect(() => {
    const interval = setInterval(handleSearch, 30000);

    return () => {
      clearInterval(interval);
    }
  }, [handleSearch]);

  useEffect(() => {
    const dateString = searchParams.get("date");
    if (dateString) {
      setDate(moment(dateString, "YYYY-MM-DD"))
    }
    const phaseString = searchParams.get("phase");
    if (phaseString) {
      setPhase(phaseString)
    }
  }, [searchParams]);

  const handleOpenBatchReleaseDialog = useCallback((queue:Queue) => {
    setSelectedQueue(queue);
    setOpenBatchReleaseDialog(true);
  }, []);

  const handleOpenBatchChangeCheckinModeDialog = useCallback((event:SummaryEvent) => {
    setSelectedEvent(event);
    setOpenBatchChangeCheckinModeDialog(true);
  }, []);

  return <>
    <Filter date={date} setDate={setDate} phase={phase} setPhase={setPhase} lastUpdate={lastUpdate} loading={loading} handleSearch={handleSearch}/>
    {
      levels.map(level => 
        <Box key={level} className="stack-on-row">
          <Box>
            <Typography variant="h3">{level}</Typography>
            <Typography variant="h4" align="center">({phase}期)</Typography>
          </Box>
          <Box className="event-cards-group">
          {_.chain(events)
          .filter(event => event.levels.includes(level))
          .groupBy("id")
          .map((events, eventId) => {
            // const queues:Queue[] = events as any;
            
            const event = _.head(events);
            const resultQueues:Queue[] = events.map(event => ({
              ...event,
              event_id: event.id,
              phase,
              level,
              total_length: 0,
              released: 0,
              ...(queues.find(queue => queue.timeslot_id === event.timeslot_id && queue.level === level && queue.phase === phase))
            }));
            if (!event) return <></>
            return <EventCard key={event.id} event={event} queues={resultQueues} handleOpenBatchReleaseDialog={handleOpenBatchReleaseDialog} handleOpenBatchChangeCheckinModeDialog={handleOpenBatchChangeCheckinModeDialog}  />
          })
          .value()}
          </Box>
        </Box>
      )
    }
    <NotificationCentre phase={phase}/>
    <BatchReleaseDialog open={openBatchReleaseDialog} setOpen={setOpenBatchReleaseDialog} handleSearch={handleSearch} queue={selectedQueue}/>
    <BatchChangeCheckinModeDialog open={openBatchChangeCheckinModeDialog} setOpen={setOpenBatchChangeCheckinModeDialog} handleSearch={handleSearch} date={moment(date).format("YYYY-MM-DD")} event={selectedEvent}/>
  </>
}