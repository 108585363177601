import { Card, Stack, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useCallback, useState } from "react";
import { postDashboardNotificationAck } from "@utils/api/dashboardNotification";
import { DashboardNotification } from "@_types/DashboardNotification";
import { toast } from "react-toastify";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

type Props = {
  notification: DashboardNotification
  handleLoad: () => any
}

export function NotificationCard({ notification, handleLoad }: Props) {
  const [sending, setSending] = useState(false);
  
  const handleAck = useCallback((id:string) => {
    setSending(true);
    postDashboardNotificationAck(id)
    .then(() => {
      // toast.success("已知悉通知");
      handleLoad();
    })
    .finally(() => {
      setSending(false);
    });
  }, [handleLoad]);

  return <Card variant="outlined" style={{ padding: "0.5em" }}>
    <Typography style={{ textAlign: "right", fontSize:"0.5em" }}>{moment(notification.createTime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
    <Typography>{notification.message.split("\n").map(str => <>{str}<br/></>)}</Typography>
    <LoadingButton color="error" loading={sending} fullWidth style={{ marginTop: "0.5em" }} onClick={() => handleAck(notification.id)}>
      <Stack direction="row" spacing={1}>
        <CheckIcon/>
        <Typography>知悉</Typography>
      </Stack>
    </LoadingButton>
  </Card>
}