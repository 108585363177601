import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Snackbar } from "@mui/material";
import { SummaryEvent } from "@_types/Event";
import React, { useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { postVehiclePassBatchUpdateCheckinMode } from "@utils/api/vehiclePass";

type Props = {
  open: boolean
  handleSearch: () => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  event?: SummaryEvent
  date: string
}

export function BatchChangeCheckinModeDialog({ open, event, setOpen, handleSearch, date }: Props) {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("FFC");
  const [message, setMessage] = useState<{
    type: AlertColor
    content: string
  }|undefined>();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSnackbarClose = useCallback(() => {
    setMessage(undefined);
  }, []);

  const handleBatchChangeCheckinMode = useCallback(() => {
    if (event) {
      setLoading(true);
      postVehiclePassBatchUpdateCheckinMode(event.id, date, mode)
      .then(() => {
        handleSearch();
        setMessage({
          type: "success",
          content: `更改報到模式成功`
        });
        handleClose();
      })
      .catch(error => {
        setMessage({
          type: "error",
          content: `更改報到模式失敗：${error.response.data}`
        });
      })
      .finally(() => {
        setLoading(false);
      })
    }
  }, [event, date, mode, handleSearch, handleClose]);

  if (!event) {
    return <></>
  }

  return <>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"} style={{ fontSize: "22px"}}>
      <DialogTitle style={{fontSize : "40px", paddingBottom:"0px", fontWeight:"bolder", padding:"30px 0 0 30px"}}>更改報到模式</DialogTitle>
      <DialogContent style={{ fontSize : "25px", padding:"0 35px 0 35px"}}>
        <ul style={{ listStyle: "none", padding: 0}}>
          <li style={{paddingBottom:"25px"}}>活動：({event.id}) {event.name}</li>
          <li >日期：{date}</li>
        </ul>
        報到模式：
        <Select size={"small"} value={mode} onChange={e => setMode(e.target.value)} style={{ fontSize : "20px"}}>
          <MenuItem value={"TY"} disabled>葵涌報到</MenuItem>
          <MenuItem value={"GF"}>會展外打卡</MenuItem>
          <MenuItem value={"FF"}>直接前往會展</MenuItem>
          <MenuItem value={"FFC"}>直接前往會展（可叫號）</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={handleClose} style={{ fontSize: "25px", width:"150px", marginBottom:"20px"}}>取消</Button>
        <LoadingButton loading={loading} variant={"contained"} onClick={handleBatchChangeCheckinMode} style={{ fontSize: "25px", width:"150px", marginLeft:"25px" , marginRight:"25px",marginBottom:"20px"}}>更改</LoadingButton>
      </DialogActions>
    </Dialog>
    <Snackbar open={!!message} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={message?.type} sx={{ width: '100%' }}>{message?.content}</Alert>
      </Snackbar>
  </>
}