import { AppBar } from '@components/AppBar';
import { Button, Typography } from '@mui/material';

export const Forbidden = function () {
  return <>
    <AppBar/>
    <Typography variant={"h4"} style={{ textAlign: "center" }}>你沒有足夠權限瀏覽本頁面</Typography>
    <Button fullWidth href={"/logout"}>登出</Button>
  </>
}
