import { LoadingButton } from "@mui/lab";
import { fetchEventReport } from "@utils/api/report";
import { downloadReport } from "@utils/downloadReport";
import { useCallback, useState } from "react";

type Props = {
  id: string
}

export function DownloadReportButton({ id }:Props) {
  const [loading, setLoading] = useState(false);

  const handleDownloadReport = useCallback(() => {
    setLoading(true);

    downloadReport(`/report/eventReport?id=${id}`, `${id}.xlsx`)
    .finally(() => {
      setLoading(false);
    })
  }, [id]);
  
  return <LoadingButton size={"small"} loading={loading} onClick={handleDownloadReport}>下載</LoadingButton>
}