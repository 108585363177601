import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { fetchSettings } from "@utils/api/setting";
import { useCallback, useEffect, useState } from "react";
import { Setting as SettingType } from "@_types/Setting";
import { SettingValue } from "./SettingValue";

export function Setting() {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<SettingType[]>([]);

  const handleLoad = useCallback(() => {
    setLoading(true);

    fetchSettings()
    .then(setSettings)
    .finally(() => setLoading(false));
  }, []);

  useEffect(handleLoad, [handleLoad]);

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell>鍵</TableCell>
        <TableCell>數值</TableCell>
        <TableCell>說明</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {settings.map(setting => <TableRow>
        <TableCell>{setting.id}</TableCell>
        <TableCell><SettingValue setting={setting}/></TableCell>
        <TableCell>{setting.description}</TableCell>
      </TableRow>)}
    </TableBody>
  </Table>
}