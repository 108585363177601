import { Box, Grid, Pagination, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Filter } from './Filter';
import { VehiclePass, VehiclePassFilter } from '@_types/VehiclePass';
import { fetchVehiclePasses } from '@utils/api/vehiclePass';
import { VehiclePassCard } from '@components/VehiclePass/VehiclePassCard';
import { MultipleSelectToolbar } from '@components/VehiclePass/MultipleSelectToolbar';
import _ from 'lodash';
import { Loader } from '@components/Loader';

export function VehiclePassList() {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [vehiclePasses, setVehiclePasses] = useState<VehiclePass[]>([]);
  const [selectedVehiclePasses, setSelectedVehiclePasses] = useState<VehiclePass[]>([]);
  const [filter, setFilter] = useState<VehiclePassFilter|undefined>();

  const handleReload = useCallback(() => {
    if (filter) {
      setLoading(true);
      fetchVehiclePasses(filter, page)
      .then(response => {
        setCount(response.count);
        setVehiclePasses(response.rows);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [filter, page]);

  const debouncedHandleReload = useMemo(() => _.throttle(handleReload, 3000), [handleReload]);

  useEffect(() => {
    debouncedHandleReload();
  }, [debouncedHandleReload]);

  return <Grid container>
    <Grid item sm={12}>
      <Box style={{ position: "sticky", top: "64px", backgroundColor: "white", zIndex: 1000, paddingTop: "1em" }}>
        <Filter setFilter={setFilter}/>
        {count > 0 && <Stack alignItems={"center"}>
          <Pagination
            count={Math.ceil(count/10)}
            page={page}
            onChange={(e, value) => setPage(value)}
            disabled={loading}
          />
        </Stack>}
      </Box>
      <Loader loading={loading}>
        {vehiclePasses.map(vehiclePass => <VehiclePassCard key={vehiclePass.id} vehiclePass={vehiclePass} selectedVehiclePasses={selectedVehiclePasses} setSelectedVehiclePasses={setSelectedVehiclePasses} handleReload={handleReload}/>)}
        {!!selectedVehiclePasses.length && <MultipleSelectToolbar selectedVehiclePasses={selectedVehiclePasses} setSelectedVehiclePasses={setSelectedVehiclePasses} handleReload={handleReload}/>}
      </Loader>
    </Grid>
    {/* <SpacesAvailable/> */}
  </Grid>;
}
