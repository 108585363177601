import moment from "moment";

export function timeslotRange(start:moment.MomentInput, end:moment.MomentInput) {
  const startTime = moment(start).format("HH:mm");
  const endTime = moment(end).format("HH:mm");
  // const endTimeHourText = (endTimeMoment.hour() + 24 * endTimeMoment.diff(moment(start).startOf("day"), "day")).toString().padStart(2, "0");
  // const endTimeMinuteText = endTimeMoment.minute().toString().padStart(2, "0");
  // const endTime = `${endTimeHourText}:${endTimeMinuteText}`

  return `${startTime} - ${endTime}`;
}