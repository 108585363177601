import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useCallback, useEffect, useState } from "react";
import { fetchCannedMessages } from "@utils/api/cannedMessage";
import { postNotifyMessage } from "@utils/api/notify";
import { LoadingButton } from "@mui/lab";

type Props = {
  open: boolean,
  handleClose: () => any
  driverPhones: string[]
}

export function SendMessageDialog({ open, handleClose, driverPhones }: Props) {
  const [cannedMessages, setCannedMessages] = useState<string[]>([]);
  const [message, setMessage] = useState("");
  const [inapp, setInapp] = useState(true);
  const [sms, setSms] = useState(false);
  const [sending, setSending] = useState(false);

  const handleSend = useCallback(() => {
    setSending(true);
    postNotifyMessage(driverPhones, message, undefined, inapp, sms?"all":"none")
    .then((response) => {
      const messages = [];

      if (response.inapp) {
        messages.push(`In-App裝置數: ${response.inapp.deviceCount}`);
      }
      if (response.sms) {
        messages.push(`SMS成功數: ${response.sms.success}`);
        messages.push(`SMS失敗數: ${response.sms.fail}`);
      }
      alert(messages.join(", "));
      handleClose();
    })
    .catch((error) => {
      alert(`未能發送訊息 (${error.response.data.message})`);
    }).finally(() => {
      setSending(false);
    });
  }, [driverPhones, message, handleClose, inapp, sms]);

  useEffect(() => {
    if (open) {
      fetchCannedMessages().then(response => setCannedMessages(response.map(x => x.content)))
    }
  }, [open]);

  return <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
    <DialogTitle>發送訊息</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {driverPhones.length === 1 ? `將會發送訊息至司機${driverPhones[0]}` :`將會發送訊息至${driverPhones.length}位司機`}
      </DialogContentText>

      <FormControl
        fullWidth
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <InputLabel>預設訊息</InputLabel>
        <Select
          onChange={(e) => setMessage(e.target.value as string)}
        >
          {cannedMessages.map(cannedMessage => <MenuItem
            key={cannedMessage}
            value={cannedMessage}
          >{cannedMessage}</MenuItem>)}
        </Select>
      </FormControl>

      <TextField
        autoFocus
        style={{ marginTop: 10, marginBottom: 10 }}
        fullWidth
        label={"訊息內容"}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <Stack direction="row">
        <FormControlLabel style={{ width: "100%" }} checked={inapp} control={<Checkbox onChange={(e) => setInapp(e.target.checked)}/>} label="In-App 通知" />
        <FormControlLabel style={{ width: "100%" }} checked={sms} control={<Checkbox onChange={(e) => setSms(e.target.checked)}/>} label="SMS 通知" />
        {/* <Select fullWidth value={sms} onChange={(e) => setSms(e.target.value)} placeholder="發送SMS模式" size="small">
          <MenuItem value="all">發送SMS至已選司機</MenuItem>
          <MenuItem value="failover">發送SMS至沒有App/已登出的司機</MenuItem>
          <MenuItem value="none">不發送SMS</MenuItem>
        </Select> */}
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button color={"error"} onClick={handleClose}>取消</Button>
      <LoadingButton disabled={!inapp && !sms} onClick={handleSend} loading={sending}>
        <SendIcon fontSize="small"/>
        發送
      </LoadingButton>
    </DialogActions>
  </Dialog>
}