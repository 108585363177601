import { api } from ".";

export async function fetchSettings() {
  const response = await api.get("/setting");

  return response.data;
}

export async function fetchSetting(id:string) {
  const response = await api.get("/setting", { params: { id }});

  return response.data;
}

export async function postSetting(id: string, value:string) {
  const response = await api.post("/setting", { id, value });

  return response.data;
}