import { Box, Stack } from "@mui/material";
import { getDashboardNotifications } from "@utils/api/dashboardNotification";
import { useCallback, useEffect, useState } from "react";
import { NotificationCard } from "./NotificationCard";
import { getRoles } from "@utils/cognito";
import { useHasRoles } from "react-simple-rbac";

type Props = {
  phase: string
}

export function NotificationCentre({ phase }: Props) {
  const isAdminDock = useHasRoles(["admin", "dock"], true);

  const [notifications, setNotifications] = useState<{
    id: string
    message: string
    createTime: string
  }[]>([]);

  const handleLoad = useCallback(() => {
    if (isAdminDock) {
      getDashboardNotifications("eventSummary", true, phase)
      .then((data) => {
        setNotifications(data);
      });
    }
  }, [isAdminDock, phase]);

  useEffect(() => {
    handleLoad();
    const interval = setInterval(handleLoad, 10000);

    return () => {
      clearInterval(interval);
    }
  }, [handleLoad]);

  if (notifications.length === 0) {
    return <></>
  }

  return <Box style={{
    position: "fixed",
    bottom: 0,
    right: 0,
    maxHeight: "70vh",
    width: 300,
    backgroundColor: "white",
    borderColor: "black",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    padding: 5,
    overflow: "auto"
  }}>
    <Stack style={{ width: "100%" }} spacing={2}>
      {notifications.map(notification => <NotificationCard key={notification.id}  notification={notification} handleLoad={handleLoad}/>)}
    </Stack>
  </Box>
}