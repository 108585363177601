import { Alert, AlertColor, Box, Button, Dialog, DialogActions, DialogContent, Snackbar, Stack, Typography } from "@mui/material";
import { VehiclePass } from '@_types/VehiclePass';
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ElevatorIcon from '@mui/icons-material/Elevator';

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  vehiclePass?: VehiclePass|null|undefined
  error?:string
  goldenButton:string | boolean | null | undefined
  needConfirmation:boolean
  setVehiclePass:React.Dispatch<React.SetStateAction<VehiclePass|null|undefined>>
  setNeedConfirmation:React.Dispatch<React.SetStateAction<boolean>>
  handleConfirm: (test:boolean, override:boolean ) => any
  loading: boolean
  verifyLocation:string
}

export function QRCodeInfo({ open,setOpen,vehiclePass,error,goldenButton,needConfirmation,setVehiclePass,setNeedConfirmation,handleConfirm,loading,verifyLocation}: Props) {
  const [message, setMessage] = useState<{
    type: AlertColor
    content: string
  }|undefined>();

  const handleClose = useCallback(() => {
      setVehiclePass(undefined);
      setNeedConfirmation(false);
      setOpen(false);
  }, [setVehiclePass, setNeedConfirmation, setOpen]);

  const status = useMemo(() => {
    if (loading) {
      return "載入中...";
    }
    if (needConfirmation) {
      return "請確認許可證資料";
    }
    return "請掃二維碼";
  }, [loading, needConfirmation]);
 
  return <>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"} style={{ fontSize: "22px", padding:"15px"}}>
      {/* <DialogTitle className="batch-release-title" >叫號</DialogTitle> */}
      <DialogContent style={{paddingBottom:"20px"}}>
      
        {vehiclePass ? 
        <Box style={{display:"flex", flexFlow:"column",height:"70vh"}}>
          {error ?  
            <Stack style={{backgroundColor:"red", justifyContent:"center", alignItems:"center",minHeight:"10vh"}} direction="row" >
              <CloseIcon sx={{color:"white",fontSize: 100}} />
              <Typography style={{ textAlign: "center", color: "white" ,fontSize:80}}>{error}</Typography>
            </Stack>
            :
            <Stack style={{backgroundColor:"#00b000", justifyContent:"center", alignItems:"center",minHeight:"10vh"}} direction="row" >
              <DoneIcon sx={{color:"white",fontSize: 100}} />
              <Typography style={{ textAlign: "center", color: "white" ,fontSize:80}}>{verifyLocation === "hkcec"? "可以進入" : "可以打卡"}</Typography>
            </Stack>
          }
          <Box style={{ flex:"2",overflow: "auto"}}>
            {vehiclePass.license &&
            <Stack direction="column">
              <Typography style={{ fontSize:30 , color: "grey"}}>活動：</Typography>
              <Typography style={{ fontSize:35 , color: "black"}}>({vehiclePass.event?.id}) {vehiclePass.event?.name}</Typography>
            </Stack>
             } 
            {vehiclePass.license &&
            <Stack direction="column">
              <Typography style={{ fontSize:30 , color: "grey"}}>期數/樓層：</Typography>
              {vehiclePass.enableLift ?
                <Stack direction="row">
                  <Typography style={{ fontSize:50 , color: "black", marginRight:10}}>{vehiclePass.phase}期 - {vehiclePass.level}</Typography>
                  <Box style={{ display:"flex", alignItems:"center"}}><ElevatorIcon color="success" sx={{ fontSize: 60 }}/></Box>
                </Stack>
                :
                <Typography style={{ fontSize:50 , color: "black"}}>{vehiclePass.phase}期 - {vehiclePass.level}</Typography>
              }
            </Stack>
            } 
            {vehiclePass.license &&
            <Stack direction="column">
              <Typography style={{ fontSize:30 , color: "grey"}}>時段：</Typography>
              <Typography style={{ fontSize:50 , color: "black"}}>{moment(vehiclePass.selectedTimeslot?.startTime).format("DD/MM HH:mm")} - {moment(vehiclePass.selectedTimeslot?.endTime).format("DD/MM HH:mm")}</Typography>
            </Stack>
            } 
            {vehiclePass.license &&
            <Stack direction="column">
              <Typography style={{ fontSize:30 , color: "grey"}}>車牌：</Typography>
              <Typography style={{ fontSize:50 , color: "black"}}>{vehiclePass.license}</Typography>
            </Stack>
            } 
            {/* {vehiclePass.enableLift && vehiclePass.license &&
            <Stack direction="row" style={{ alignItems:"center"}}>
              <Typography style={{ fontSize:30 , color: "grey"}}>是否允許使用一期貨車升降機？：</Typography>
              <Typography style={{ fontSize:50 }}><ElevatorIcon color="success" sx={{ fontSize: 40 }}/></Typography>
            </Stack>
            } */}
          </Box>
          <Stack style={{ marginTop: "1em", fontSize: "large" ,height: 60}} direction="row">
         
         {/* <Box style={{display:"flex", height:"30vh",justifyContent: "space-evenly",alignItems:"center", flexDirection:"column"}}>
         <Typography style={{ fontSize:30 }}>{status}</Typography>
       </Box> */}
          {loading ? 
            <Button variant={"outlined"} style={{ fontSize:30, width:"50%",marginRight:20}}>{status}</Button>
            :
            <Button variant={"outlined"} onClick={handleClose} style={{ fontSize:30, width:"50%",marginRight:20}}>取消</Button>
          }
            {goldenButton && <LoadingButton
              fullWidth
              style={{  width:"50%",fontSize:30 }}
              variant={"contained"}
              loading={loading}
              color="warning"
              onClick={() => handleConfirm(false, true)}
            >
              強制放行
            </LoadingButton>}
          {needConfirmation && 
            <LoadingButton
              fullWidth
              style={{  flex: 1,fontSize:30}}
              variant={"contained"}
              loading={loading}
              onClick={() => handleConfirm(false,false)}
            >
              確認
            </LoadingButton>
            }
          </Stack>
            
         </Box>
        :
        
        <Box style={{display:"flex", height:"30vh",justifyContent: "space-evenly",alignItems:"center", flexDirection:"column"}}>
          {error && 
          <Stack style={{backgroundColor:"red", justifyContent:"center", alignItems:"center",minHeight:"10vh"}} direction="row" >
              <CloseIcon sx={{color:"white",fontSize: 100}} />
              <Typography style={{ textAlign: "center", color: "white" ,fontSize:80}}>{error}</Typography>
            </Stack>
            }
          <Stack style={{ marginTop: "2em", fontSize: "large" ,minHeight: "60px"}} direction="row">
          {loading ? 
            <Button variant={"outlined"} onClick={handleClose} style={{ fontSize:30,width:"300px"}}>{status}</Button>
            :
            <Button variant={"outlined"} onClick={handleClose} style={{ fontSize:30,width:"300px"}}>取消</Button>
          }
              
          </Stack>
        </Box>
          
        }
      </DialogContent>
      <DialogActions>
        
       
      </DialogActions>
    </Dialog>
    <Snackbar open={!!message} autoHideDuration={5000} onClose={() => setMessage(undefined)}>
      <Alert onClose={() => setMessage(undefined)} severity={message?.type} sx={{ width: '100%' }}>{message?.content}</Alert>
    </Snackbar>
  </>
}