import { Alert, AlertColor, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import RefreshIcon from "@mui/icons-material/Refresh";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { fetchVehiclePass, postVehiclePass, putVehiclePass } from '@utils/api/vehiclePass';
import { fetchTimeslotsByEventId } from '@utils/api/timeslot';
import { LoadingButton } from '@mui/lab';

export const AddModifyVehiclePass = function () {
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const [id, setId] = useState("");
  const [passId, setPassId] = useState("");
  const [phase, setPhase] = useState("");
  const [qrHash, setQrHash] = useState("");
  const [enableLift, setEnableLift] = useState(false);
  const [alwaysEnable, setAlwaysEnable] = useState(false);
  const [mode, setMode] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventName, setEventName] = useState("");
  const [level, setLevel] = useState("");
  const [venue, setVenue] = useState("");
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState("");
  const [license, setLicense] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [timeslotId, setTimeslotId] = useState("");
  const [queuePos, setQueuePos] = useState("");
  const [timeslots, setTimeslots] = useState<{id:string, startTime:moment.Moment, endTime:moment.Moment}[]>([]);
  const [eventStartDate, setEventStartDate] = useState<moment.Moment|null>(null);
  const [eventEndDate, setEventEndDate] = useState<moment.Moment|null>(null);
  const [levels, setLevels] = useState<string[]>([]);
  const [phases, setPhases] = useState<string[]>([]);

  const [message, setMessage] = useState<{
    type: AlertColor
    content: string
  }|undefined>();
  // const [tempStartTime, setTempStartTime] = useState<moment.Moment|null>(null);
  // const [tempEndTime, setTempEndTime] = useState<moment.Moment|null>(null);

  const addOrModify = useMemo(() => !id?"新增":"更改", [id]);

  const handleLoadVehiclePass = useCallback(() => {
    const id = searchParams.get("id");
    if (id) {
      setLoading(true);
      fetchVehiclePass({ id }).then((response) => {
        setId(response.id);
        setPassId(response.passId || "");
        setPhase(response.phase || "");
        setQrHash(response.qrHash || "");
        setEnableLift(response.enableLift);
        setMode(response.mode);
        setAlwaysEnable(response.alwaysEnable);
        setEventId(response.event?.id || "");
        setEventName(response.event?.name || "");
        setEventStartDate(response.event?.startDate ? moment(response.event?.startDate) : null);
        setEventEndDate(response.event?.endDate ? moment(response.event?.endDate) : null);
        setLevel(response.level || "");
        setVenue(response.venue || "");
        setRemarks(response.remarks || "");
        setStatus(response.status || "");
        setLicense(response.license || "");
        setDriverPhone(response.driverPhone || "");
        setQueuePos(response.queuePos?.toString() || "");
        setTimeslotId(response.timeslotId || "");
        setTimeslots(response.availableTimeslots?.map(timeslot => ({
          id: timeslot.id,
          startTime: moment(timeslot.startTime),
          endTime: moment(timeslot.endTime),
        })) || []);
        setLevels(response.event?.levels || []);
        setPhases(response.event?.phases || []);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [searchParams]);

  const handleReloadTimeslots = useCallback(() => {
    fetchTimeslotsByEventId(eventId).then(response => {
      setTimeslots(response.map(timeslot => ({
        id: timeslot.id,
        startTime: moment(timeslot.startTime),
        endTime: moment(timeslot.endTime),
      })) || []);
    });
  }, [eventId]);

  const handleModify = useCallback(() => {
    setSending(true);

    let sendFunction;
    if (id) {
      sendFunction = () => putVehiclePass({
        id,
        passId,
        qrHash,
        phase,
        enableLift,
        mode,
        alwaysEnable,
        eventId,
        eventName,
        eventStartDate,
        eventEndDate,
        level,
        venue,
        status,
        queuePos: queuePos && Number(queuePos),
        remarks,
        license,
        driverPhone,
        timeslotId,
        timeslots
      })
    } else {
      sendFunction = () => postVehiclePass({
        passId,
        qrHash,
        phase,
        enableLift,
        mode,
        alwaysEnable,
        eventId,
        eventName,
        eventStartDate,
        eventEndDate,
        level,
        venue,
        status,
        queuePos: queuePos && Number(queuePos),
        remarks,
        license,
        driverPhone,
        timeslotId,
        timeslots
      })
    }

    sendFunction().then(() => {
      setMessage({
        type: "success",
        content: `已成功${addOrModify}`
      });
      handleLoadVehiclePass();
    }).catch(error => {
      setMessage({
        type: "error",
        content: error.response.data.message || error.message
      });
    }).finally(() => {
      setSending(false);
    });
  }, [
    handleLoadVehiclePass,
    id,
    passId,
    qrHash,
    phase,
    enableLift,
    mode,
    alwaysEnable,
    eventId,
    eventName,
    eventStartDate,
    eventEndDate,
    level,
    venue,
    remarks,
    license,
    queuePos,
    driverPhone,
    timeslots,
    timeslotId,
    status,
    addOrModify,
  ]);

  useEffect(() => {
    handleLoadVehiclePass();
  }, [handleLoadVehiclePass]);

  // const handleAddTimeslot = useCallback(() => {
  //   if (!tempStartTime) {
  //     alert("必需輸入進場時段開始時間");
  //   } else if (!tempEndTime) {
  //     alert("必需輸入進場時段結束時間");
  //   } else {
  //     if (timeslots.find(timeslot => timeslot.startTime.isSame(tempStartTime) && timeslot.endTime.isSame(tempEndTime))) {
  //       alert("已有同樣時段");
  //     } else if (tempStartTime.isAfter(tempEndTime)) {
  //       alert("結束時間不能早於開始時間");
  //     } else {
  //       setTimeslots((value) => [...value, {
  //         id: `${eventId}-${tempStartTime.format("YYYYMMDDHHmm")}`,
  //         startTime: tempStartTime,
  //         endTime: tempEndTime
  //       }]);
  //     }
  //   }
  // }, [eventId, tempStartTime, tempEndTime, timeslots]);

  // const handleDeleteTimeslot = useCallback((i:number) => {
  //   setTimeslots(value => value.filter((timeslot, j) => i !== j));
  // }, []);

  if (loading) {
    return <CircularProgress/>
  }

  return <>
    <Typography variant={"h4"} style={{marginBottom: "10px"}}>{addOrModify}許可證</Typography>
    <Stack direction={"row"}>
      <TextField fullWidth style={{marginBottom: "1em"}} required value={passId} onChange={(e) => setPassId(e.target.value)} label={"許可證編號"}/>
      <FormControl fullWidth disabled={["MANUAL_CREATED", "IMPORTED"].includes(status)}>
        <InputLabel>卸貨區期數</InputLabel>
        <Select fullWidth value={phase} onChange={(e) => setPhase(e.target.value)}>
          {phases.map(phase => <MenuItem key={phase} value={phase}>{phase}</MenuItem>)}
        </Select>
      </FormControl>
      <TextField fullWidth style={{marginBottom: "1em"}} required value={qrHash} onChange={(e) => setQrHash(e.target.value)} label={"二維碼內容"}/>
    </Stack>
    
    <Stack direction={"row"} style={{ marginBottom: "1em" }}>
      <FormControl fullWidth style={{marginBottom: "1em"}}>
        <InputLabel>報到模式</InputLabel>
        <Select size="small" fullWidth value={mode} onChange={(e) => setMode(e.target.value)}>
          <MenuItem value={"TY"}>葵涌打卡</MenuItem>
          <MenuItem value={"GF"}>場外自行打卡</MenuItem>
          <MenuItem value={"FF"}>直接前往會展</MenuItem>
          <MenuItem value={"FFC"}>直接前往會展（可叫號）</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel style={{width: "100%"}} label={"是否允許使用一期貨車升降機？"} control={<Checkbox checked={enableLift} onChange={(e) => setEnableLift(e.target.checked)}/>}/>
      <FormControlLabel style={{width: "100%"}} label={"報到永不逾時"} control={<Checkbox checked={alwaysEnable} onChange={(e) => setAlwaysEnable(e.target.checked)}/>}/>
    </Stack>
    
    <Grid container style={{marginBottom: "1em"}} >
      <Grid item sm={2}>
        <TextField fullWidth required value={eventId} onChange={(e) => setEventId(e.target.value)} label={"活動編號"}/>
      </Grid>
      <Grid item sm={10}>
        <TextField fullWidth required value={eventName} onChange={(e) => setEventName(e.target.value)} label={"活動名稱"}/>
      </Grid>
    </Grid>
    <Stack direction={"row"} style={{ marginBottom: "1em" }}>
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true,
            required: true
          }
        }}
        label={"活動開始日期"}
        value={eventStartDate}
        onChange={setEventStartDate}
        format={"D MMM YYYY"}
      />
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true,
            required: true
          }
        }}
        label={"活動結束日期"}
        value={eventEndDate}
        onChange={setEventEndDate}
        format={"D MMM YYYY"}
      />
    </Stack>

    <Grid container>
      <Grid item sm={2}>
        <FormControl fullWidth disabled={["MANUAL_CREATED", "IMPORTED"].includes(status)}>
          <InputLabel>樓層</InputLabel>
          <Select fullWidth value={level} onChange={(e) => setLevel(e.target.value)}>
            {levels.map(level => <MenuItem key={level} value={level}>{level}</MenuItem>)}
          </Select>
        </FormControl>
        {/* <TextField fullWidth style={{marginBottom: "1em"}} value={level} onChange={(e) => setLevel(e.target.value)} label={"樓層"}/> */}
      </Grid>
      <Grid item sm={10}>
        <TextField fullWidth style={{marginBottom: "1em"}} value={venue} onChange={(e) => setVenue(e.target.value)} label={"場地"}/>
      </Grid>
    </Grid>
    <TextField fullWidth style={{marginBottom: "1em"}} value={remarks} onChange={(e) => setRemarks(e.target.value)} label={"備註"}/>
    
    <Stack direction={"row"} style={{marginBottom: "1em"}}>
      <TextField fullWidth value={license} disabled={["MANUAL_CREATED", "IMPORTED"].includes(status)} onChange={(e) => setLicense(e.target.value)} label={"車牌"}/>
      <TextField fullWidth value={driverPhone} disabled={["MANUAL_CREATED", "IMPORTED"].includes(status)} onChange={(e) => setDriverPhone(e.target.value)} label={"司機電話"}/>
    </Stack>

    <Grid container spacing={2} style={{marginBottom: "1em"}}>
      <Grid item sm={10}>
        <Stack direction={"row"}>
          <FormControl fullWidth>
            <InputLabel>已選時段</InputLabel>
            <Select fullWidth value={timeslotId} onChange={(e) => setTimeslotId(e.target.value)}>
              {timeslots.map(timeslot => <MenuItem key={timeslot.id} value={timeslot.id}>{timeslot.startTime.format("D MMM HH:mm")} - {timeslot.endTime.format("D MMM HH:mm")}</MenuItem>)}
            </Select>
          </FormControl>
          <Button onClick={handleReloadTimeslots}><RefreshIcon/></Button>
        </Stack>
      </Grid>
      <Grid item sm={2}>
        <TextField fullWidth value={queuePos} disabled={["MANUAL_CREATED", "IMPORTED", "BINDED"].includes(status)} onChange={(e) => setQueuePos(e.target.value)} label={"隊列位置"}/>
      </Grid>
    </Grid>
    

    <FormControl fullWidth style={{marginBottom: "1em"}}>
      <InputLabel>狀態</InputLabel>
      <Select fullWidth value={status} onChange={(e) => setStatus(e.target.value)}>
        <MenuItem value={"MANUAL_CREATED"}>已手動新增</MenuItem>
        <MenuItem value={"IMPORTED"}>已匯入</MenuItem>
        <MenuItem value={"BINDED"}>已登記</MenuItem>
        <MenuItem value={"QUEUED"}>已取籌（未放行）</MenuItem>
        <MenuItem value={"RELEASED"}>已放行</MenuItem>
        <MenuItem value={"CHECKED_IN"}>已打卡</MenuItem>
        <MenuItem value={"FINISHED"}>已到達會展</MenuItem>
      </Select>
    </FormControl>

    {/* <Paper style={{ marginBottom: "1em" }}>
      <Typography variant={"h6"} style={{marginBottom: "10px"}}>進場時間</Typography>
      {timeslots.length > 0 && <Table>
        <TableHead>
          <TableRow>
            <TableCell>開始時間</TableCell>
            <TableCell>結束時間</TableCell>
            <TableCell>刪除時段</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeslots.map((timeslot, i) => <TableRow key={i}>
            <TableCell>{timeslot.startTime.format("D MMM HH:mm")}</TableCell>
            <TableCell>{timeslot.endTime.format("D MMM HH:mm")}</TableCell>
            <TableCell><Button color={"error"} onClick={() => handleDeleteTimeslot(i)}><DeleteIcon/></Button></TableCell>
          </TableRow>)}
        </TableBody>
      </Table>}

      <Stack direction={"row"}>
          <DateTimePicker
            renderInput={(props) => <TextField fullWidth size={"small"} {...props} />}
            label="開始時間"
            value={tempStartTime}
            onChange={(newValue) => {
              setTempStartTime(newValue);
            }}
            inputFormat={"D MMM HHmm"}
          />
          <DateTimePicker
            renderInput={(props) => <TextField fullWidth size={"small"} {...props} />}
            label="結束時間"
            value={tempEndTime}
            onChange={(newValue) => {
              setTempEndTime(newValue);
            }}
            inputFormat={"D MMM HHmm"}
          />
        <Button fullWidth color={"success"} onClick={handleAddTimeslot} startIcon={<AddIcon/>}>
          新增時段
        </Button>
      </Stack>
    </Paper> */}
    
    <LoadingButton fullWidth variant={"contained"} style={{position:"sticky", bottom: 0, zIndex: 1000}} onClick={handleModify} loading={sending}>{addOrModify}</LoadingButton>


    <Snackbar open={!!message} autoHideDuration={5000} onClose={() => setMessage(undefined)}>
      <Alert onClose={() => setMessage(undefined)} severity={message?.type} sx={{ width: '100%' }}>{message?.content}</Alert>
    </Snackbar>
  </>;
}
