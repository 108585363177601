import { Box, Button, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DateTimePicker } from "@mui/x-date-pickers";
import { fetchReport } from "@utils/api/report";
import { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Spreadsheet from "react-spreadsheet";
import { downloadReport } from "@utils/downloadReport";

export function Reports() {
  const [searchParams] = useSearchParams();

  const [reportId, setReportId] = useState("");
  const [startTime, setStartTime] = useState<Moment|null>();
  const [endTime, setEndTime] = useState<Moment|null>();
  const [data, setData] = useState<any[]>([]);

  const filter = useMemo(() => ({
    ...(startTime && { startTime: startTime.toISOString(true) }),
    ...(endTime && { endTime: endTime.toISOString(true) }),
  }), [startTime, endTime]);

  const handleFetchReport = useCallback(() => {
    fetchReport(reportId, filter).then((response) => {
      setData(response);
    });
  }, [reportId, filter]);

  useEffect(() => {
    setReportId(searchParams.get("reportId") || "");
  }, [searchParams]);

  return <>
    <Button fullWidth onClick={() => downloadReport("/report/exported/queueReport", "queueReport.xlsx")}>隊列報告 (Excel)</Button>
    <Button fullWidth href="/reports/finishedCount">每日到達會展許可證數目</Button>
    <Button fullWidth href="/reports/eventReport">活動報告</Button>
    <Box style={{ marginTop: "1em", position: "sticky", top: "100px" }}>
      <Stack direction={"row"}>
        <DateTimePicker
          slotProps={{
            textField: {
              fullWidth: true,
            }
          }}
          label="開始時間"
          value={startTime}
          onChange={setStartTime}
          maxDateTime={endTime || undefined}
          format={"D MMM YYYY HH:mm"}
        />
        <DateTimePicker
          slotProps={{
            textField: {
              fullWidth: true,
            }
          }}
          label="結束時間"
          value={endTime}
          onChange={setEndTime}
          minDateTime={startTime || undefined}
          format={"D MMM YYYY HH:mm"}
        />
      </Stack>
      <Button fullWidth onClick={handleFetchReport} startIcon={<SearchIcon/>}>搜尋</Button>
    </Box>
    <Box style={{ width: "100%", overflow: "auto" }}>
      <Spreadsheet
        data={data}
      />
    </Box>
  </>
}