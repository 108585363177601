import { VehiclePass, VehiclePassFilter } from "@_types/VehiclePass";
import { api, Paginated } from ".";

export async function fetchVehiclePass(params: {
  id?: string
  qrHash?: string
}) {
  const response = await api.get<VehiclePass>("/vehiclePass", { params });

  return response.data;
}

export async function fetchVehiclePasses(params: VehiclePassFilter, page:number) {
  const response = await api.get<Paginated<VehiclePass>>("/vehiclePass", {
    params: {
      ...params,
      ...(params.status && { status: params.status.join(",") }),
      page
    }
  });

  return response.data;
}

export async function fetchVehiclePassesCount(params: VehiclePassFilter) {
  const response = await api.get<{[key:string]:number}>("/vehiclePass", {
    params: {
      ...params,
      count: "true"
    }
  });

  return response.data;
}

export async function postVehiclePass(payload:any) {
  const response = await api.post("/vehiclePass", payload);

  return response.data;
}

export async function putVehiclePass(payload:any) {
  const response = await api.put("/vehiclePass", payload);

  return response.data;
}

export async function postVehiclePassCheckin(id:string, verifyLocationId:string, test:boolean, override:boolean) {
  const response = await api.post("/vehiclePass/checkIn", { id, verifyLocationId, test, override });

  return response.data;
}

export async function postVehiclePassRelease(id:string) {
  const response = await api.post("/vehiclePass/release", { id });

  return response.data;
}

export async function postVehiclePassBatchImport(body: FormData) {
  const response = await api.post("/vehiclePass/import", body, {
    headers: { "content-type": "multipart/form-data" }
  })

  return response.data;
}

export async function deleteVehiclePass(id:string) {
  const response = await api.delete("/vehiclePass", { data: { id } });

  return response.data;
}

export async function postVehiclePassBatchRelease(timeslotId:string, phase:string, level:string, count:number) {
  const response = await api.post("/vehiclePass/batchRelease", {
    phase,
    level,
    timeslotId,
    count
  });

  return response.data;
}

export async function postVehiclePassBatchUpdateCheckinMode(eventId:string, date: string, mode:string) {
  const response = await api.post("/vehiclePass/batchUpdateCheckinMode", {
    eventId,
    date,
    mode
  });

  return response.data;
}