import { BatchImportVehiclePass } from "./BatchImportVehiclePass";
import { BroadcastMessage } from "./BroadcastMessage";
import { DriverList } from "./DriverList";
import { Home } from "./Home";
import { AddModifyVehiclePass } from "./AddModifyVehiclePass";
import { VehiclePassList } from "./VehiclePassList";
import { VehicleList } from "./VehicleList";
import { VerifyQRCode } from "./VerifyQRCode";
import { Login } from "./Login";
import { ProtectedRoute } from "@components/ProtectedRoute";
import { Logout } from "./Logout";
import { Reports } from "./Reports";
import { Call } from "./Call";
import { ImportVehiclePassTask } from "./ImportVehiclePassTask";
import { ReportsFinishedCount } from "./Reports/FinishedCount";
import { ReportsEventReport } from "./Reports/EventReport";
import { Setting } from "./Setting";

export const pages:{
  path: string,
  name: string,
  element: JSX.Element,
  requiredRoles?: string[]
  hidden?: boolean
  hideAppbar?: boolean
}[] = [
  {
    path: "/login",
    name: "登入",
    element: <Login/>,
    hidden: true,
    hideAppbar: true
  },
  {
    path: "/",
    name: "首頁",
    element: <ProtectedRoute><Home/></ProtectedRoute>
  },
  {
    path: "/call",
    name: "叫號",
    element: <Call/>,
    requiredRoles: ["admin", "dock", "epc", "ty"]
  },
  {
    path: "/vehiclePass",
    name: "所有許可證",
    element: <VehiclePassList/>,
    requiredRoles: ["admin", "dock", "ty"]
  },
  {
    path: "/scanQRCodeTy",
    name: "QR 掃描 (葵涌)",
    element: <VerifyQRCode verifyLocation={"ty"}/>,
    requiredRoles: ["admin", "ty"]
  },
  {
    path: "/scanQRCodeHkcec",
    name: "QR 掃描 (會展)",
    element: <VerifyQRCode verifyLocation={"hkcec"}/>,
    requiredRoles: ["admin", "dock"]
  },
  {
    path: "/broadcastMessage",
    name: "廣播訊息",
    element: <BroadcastMessage/>,
    requiredRoles: ["admin", "dock"]
  },
  {
    path: "/driver",
    name: "司機列表",
    element: <DriverList/>,
    requiredRoles: ["admin", "dock"]
  },
  {
    path: "/vehicle",
    name: "車輛列表",
    element: <VehicleList/>,
    requiredRoles: ["admin", "dock"]
  },
  {
    path: "/batchImportVehiclePass",
    name: "匯入許可證",
    element: <BatchImportVehiclePass/>,
    requiredRoles: ["admin", "epc"]
  },
  {
    path: "/importVehiclePassTask",
    name: "匯入許可證結果",
    element: <ImportVehiclePassTask/>,
    requiredRoles: ["admin", "epc"]
  },
  {
    path: "/addModifyVehiclePass",
    name: "新增許可證",
    element: <AddModifyVehiclePass/>,
    requiredRoles: ["admin"]
  },
  {
    path: "/reports",
    name: "報告",
    element: <Reports/>,
    requiredRoles: ["admin", "dock"]
  },
  {
    path: "/reports/finishedCount",
    name: "報告",
    hidden: true,
    element: <ReportsFinishedCount/>,
    requiredRoles: ["admin", "dock"]
  },
  {
    path: "/reports/eventReport",
    name: "活動報告",
    hidden: true,
    element: <ReportsEventReport/>,
    requiredRoles: ["admin", "dock"]
  },
  {
    path: "/setting",
    name: "系統設定",
    element: <Setting/>,
    requiredRoles: ["admin"]
  },
  {
    path: "/logout",
    name: "登出",
    element: <Logout/>
  },
]