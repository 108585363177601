import { DataGrid, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { importTasksGet } from "@utils/api/importTask";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import "./index.css";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { ImportTaskDetails } from "./ImportTaskDetails";

function getImportTaskStatus(row:any) {
  const vpCount = row.vehiclePassCount || 0;
  const filesCount = row.files?.length || 0;
  const failedFilesCount = row.failedFiles?.length || 0;
  if (vpCount === filesCount) {
    return "success";
  } else if (vpCount + failedFilesCount < filesCount) {
    return "processing";
  } else if (vpCount + failedFilesCount === filesCount) {
    return "failed";
  }
  return "";
}

export function ImportVehiclePassTask() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createTime", sort: "desc" }
  ]);

  const handleLoadData = useCallback(() => {
    setLoading(true);
    importTasksGet({}, paginationModel.page, paginationModel.pageSize, sortModel)
    .then((data) => {
      setCount(data.count);
      setRows((data.rows as any[]).map(row => {
        let status = getImportTaskStatus(row);
        if (status === "success") {
          status = "成功";
        } else if (status === "processing") {
          status = "處理中"
        } else if (status === "failed") {
          status = "失敗"
        }
        return {
          ...row,
          status
        }
      }));
    })
    .catch(error => {
      window.alert(error.response?.data?.message || error.message);
    })
    .finally(() => {
      setLoading(false);
    })
  }, [paginationModel, sortModel]);

  useEffect(() => {
    handleLoadData();

    const interval = setInterval(handleLoadData, 10000);

    return () => clearInterval(interval);
  }, [handleLoadData]);

  return <>
    <LoadingButton fullWidth loading={loading} onClick={handleLoadData}>重新載入</LoadingButton>
    <DataGrid
      autoHeight
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      rowCount={count}
      loading={loading}
      onRowClick={({ row }) => {
        setSearchParams({ id: row.id })
      }}
      columns={[
        // { field: "id", headerName: "ID", width: 300 },
        { field: "files", headerName: "所有檔案", width: 300 },
        { field: "vehiclePassCount", headerName: "許可證數目" },
        { field: "createTime", headerName: "上載時間", width: 180, valueFormatter: ({value}) => moment(value).format("YYYY-MM-DD HH:mm:ss")},
        { field: "updateTime", headerName: "最後更新時間", width: 180, valueFormatter: ({value}) => moment(value).format("YYYY-MM-DD HH:mm:ss")},
        { field: "failedFiles", headerName: "處理失敗檔案", width: 300 },
        { field: "status", headerName: "狀態" },
      ]}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      rows={rows}
      getRowClassName={({row}) => {
        return getImportTaskStatus(row);
      }}
    />
    <ImportTaskDetails open={searchParams.has("id")} onClose={() => setSearchParams({})}/>
  </>
}