import { api } from ".";

export async function postNotifyMessage(driverPhones:string[], content:string, title:string|undefined, inapp:boolean, sms:string) {
  const response = await api.post<{title?:string, content:string, inapp?:{deviceCount:number}, sms?:{success: number, fail:number}}>("/notify", {
    driverPhones,
    title,
    content,
    inapp,
    sms
  });

  return response.data;
}

export async function postBroadcastMessage(content:string, title:string|undefined, inapp:boolean, sms:string) {
  const response = await api.post<{title?:string, content:string, inapp?:{deviceCount:number}, sms?:{success: number, fail:number}}>("/notify/broadcast", {
    title,
    content,
    inapp,
    sms
  });

  return response.data;
}