import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Stack, Typography, Grid } from "@mui/material";
import { Queue } from "@_types/Queue";
import { SummaryEvent } from "@_types/Event";
import './EventCard.css'
import { useCallback } from "react";
import _ from "lodash";
import { timeslotRange } from "@utils/timeslotRange";
import { useHasRoles } from "react-simple-rbac";

type Props = {
  event: SummaryEvent
  queues: Queue[];
  handleOpenBatchReleaseDialog: (queue: Queue) => void
  handleOpenBatchChangeCheckinModeDialog: (event: SummaryEvent) => void
}

export function EventCard({ event, queues, handleOpenBatchReleaseDialog, handleOpenBatchChangeCheckinModeDialog }:Props) {
  const isAdmin = useHasRoles(["admin", "dock"], true);

  const changeModeText = useCallback(({mode}:Queue) => {
    return mode || "更改報到模式";
  }, []);

  const batchReleaseButtonDisabled = useCallback((queue:Queue) => {
    if (!isAdmin) {
      return true;
    }
    if (queue.mode === "FF" || queue.released >= queue.total_length) {
      return true;
    }
    return false;
  }, [isAdmin]);
  
  return <Box className='event-card' >
    <Typography variant={"h1"}>({event.id}) {event.name}</Typography>
    <Stack direction={"column"} spacing={1} >
      {queues.map(queue => <Card key={queue.timeslot_id} className="event-timeslot">
        <Stack direction={"row"} spacing={1}>
          <Box className="event-card-timeslot" style={{flex:3}}>{timeslotRange(queue.start_time, queue.end_time)}</Box>
          <Stack style={{flex:2}}>
            <Box className="event-card-amountLeft" style={{paddingLeft:0, fontSize:10, marginBottom:0}}>已叫/籌數 (叫到籌號)</Box>
            <Box className="event-card-amountLeft" style={{paddingLeft:0}}>{queue.released}/{queue.total_length}{queue.last_called && <span className="last-called"> (#{queue.last_called.toString().padStart(3, "0")})</span>}</Box>
          </Stack>
          <LoadingButton fullWidth style={{flex:1}} variant={"contained"} size={"small"} disabled={batchReleaseButtonDisabled(queue)} onClick={() => handleOpenBatchReleaseDialog(queue)}>叫號</LoadingButton>
          <Button fullWidth style={{flex:1}} size={"small"} disabled={!isAdmin} onClick={() => handleOpenBatchChangeCheckinModeDialog(event)}>{changeModeText(queue)}</Button>
        </Stack>
      </Card>)}
    </Stack> 
  </Box>
}