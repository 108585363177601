export function vehiclePassColour(id:string) {
  if (id.startsWith("YP-")) {
    return "#f1cb00"
  } else if (id.startsWith("PK-")) {
    return "pink"
  } else if (id.startsWith("BP-")) {
    return "#7ea7fc"
  } else if (id.startsWith("RP-")) {
    return "#ff3c5a"
  } else if (id.startsWith("P-")) {
    return "#c07fff"
  } else if (id.startsWith("G-")) {
    return "#348d3b"
  }
}