import { cognitoUserSession } from "@utils/cognito";
import { PropsWithChildren } from "react"
import { Navigate } from "react-router-dom";

export function ProtectedRoute(props:PropsWithChildren) {
  if (!cognitoUserSession) {
    return <Navigate replace to="/login" />;
  }

  return <>
    {props.children}
  </>;
}