import { Alert, AlertColor, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Stack, TextField } from "@mui/material";
import { Queue } from "@_types/Queue";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LoadingButton } from "@mui/lab";
import { postVehiclePassBatchRelease } from "@utils/api/vehiclePass";
import './BatchReleaseDialog.css';
import { timeslotRange } from "@utils/timeslotRange";

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  queue?: Queue
  handleSearch: () => any
}

export function BatchReleaseDialog({ open, queue, setOpen, handleSearch }: Props) {
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{
    type: AlertColor
    content: string
  }|undefined>();

  const canAdd = useMemo(() => {
    return !!(queue && (queue.released + count + 1 <= queue.total_length));
  }, [count, queue]);

  const canSubtract = useMemo(() => {
    return !!(queue && (count > 1));
  }, [count, queue]);

  const handleAdd = useCallback(() => {
    if (canAdd) {
      setCount(x => x+1);
    }
  }, [canAdd]);

  const handleSubtract = useCallback(() => {
    if (canSubtract) {
      setCount(x => x-1);
    }
  }, [canSubtract]);

  const handleClose = useCallback(() => {
    if (!loading) {
      setOpen(false);
    }
  }, [loading, setOpen]);

  const handleBatchRelease = useCallback(() => {
    if (queue) {
      setLoading(true);
      postVehiclePassBatchRelease(queue.timeslot_id, queue.phase, queue.level, count)
      .then(() => {
        handleSearch();
        setMessage({
          type: "success",
          content: "叫號成功"
        });
        handleClose();
      })
      .catch((error) => {
        setMessage({
          type: "error",
          content: `叫號失敗: ${error.response.data}`
        });
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [queue, count, handleSearch, handleClose]);

  useEffect(() => {
    if (open) {
      setCount(1);
    }
  }, [open])
  
  if (!queue) {
    return <></>
  }

  return <>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"} style={{ fontSize: "22px", padding:"15px"}}>
      <DialogTitle className="batch-release-title" >叫號</DialogTitle>
      <DialogContent style={{paddingBottom:"20px"}}>
        <ul className="batch-release-ul">
          <li style={{paddingBottom:"30px", fontSize : "28px"}}>活動：({queue.event_id}) {queue.name}</li>
          <li style={{paddingBottom:"10px"}}>日期：{moment(queue.start_time).format("YYYY-MM-DD")}</li>
          <li style={{paddingBottom:"10px"}}>時段：{timeslotRange(queue.start_time, queue.end_time)}</li>
          <li style={{paddingBottom:"10px"}}>期數：{queue.phase}期</li>
          <li  >樓層：{queue.level}</li>
        </ul>
        <Box textAlign={"center"} style={{display: "flex", justifyContent: "center"}}>
          <Stack direction="row" width={"50%"}>
            <Button disabled={!canSubtract} onClick={handleSubtract} style={{borderRadius:"10px", marginRight:"10px"}}><RemoveIcon/></Button>
            <TextField className="number-TextField" style={{border:"0px", marginRight:"10px", fontSize:"25px"}} fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={count} onChange={e => !isNaN(Number(e.target.value)) && setCount(Number(e.target.value))}></TextField>
            <Button disabled={!canAdd} onClick={handleAdd} style={{borderRadius:"10px"}}><AddIcon/></Button>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={handleClose} style={{ fontSize: "25px", width:"150px", marginBottom:"20px", marginTop:"0px"}}>取消</Button>
        <LoadingButton loading={loading} variant={"contained"} onClick={handleBatchRelease} style={{ fontSize: "25px", width:"150px", marginLeft:"25px" , marginRight:"25px",marginBottom:"20px"}}>叫號</LoadingButton>
      </DialogActions>
    </Dialog>
    <Snackbar open={!!message} autoHideDuration={5000} onClose={() => setMessage(undefined)}>
      <Alert onClose={() => setMessage(undefined)} severity={message?.type} sx={{ width: '100%' }}>{message?.content}</Alert>
    </Snackbar>
  </>
}