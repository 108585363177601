import { Stack, CircularProgress } from "@mui/material"

type Props = {
  loading: boolean
}

export function Loader(props:React.PropsWithChildren<Props>) {
  if (props.loading) {
    return <Stack style={{ margin: "1em" }} alignItems={"center"}>
      <CircularProgress/>
    </Stack>
  }
  return <>
    {props.children}
  </>;
}