import { cognitoRefreshSession, cognitoUserSession } from "@utils/cognito";
import axios, { AxiosRequestConfig, isAxiosError } from "axios";
import { toast } from "react-toastify";

const baseURL = "/api";

export const api = axios.create({ baseURL });

api.interceptors.request.use(async function (config) {
  const accessToken = cognitoUserSession?.getAccessToken().getJwtToken();
  if (accessToken) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`
    };
  }
  return config;
});

api.interceptors.response.use(undefined, async function (error) {
  if (isAxiosError(error)) {
    const originalRequest:(AxiosRequestConfig<any> & {_retry?:boolean})|undefined = error.config;
    if (originalRequest && error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log("Try to refresh access token");
      const accessToken = await cognitoRefreshSession();

      if (accessToken && originalRequest) {
        console.log("redo original request");
        return api(originalRequest);
      }
    }
  }
  toast.error(error.response?.data?.message || error.message);
  return Promise.reject(error);
});

export type Paginated<T> = {
  count: number
  rows: T[]
}

export type PaginationParams = {
  page?: number
  size: number
}