import { Box, Button, Card, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { postVehicle } from "@utils/api/vehicle";
import { Vehicle } from "@_types/Vehicle";
import { useCallback, useMemo } from "react";

type Props = {
  vehicle: Vehicle
  selectedVehicles: Vehicle[]
  handleReload: () => any
  handleSelected: () => any
}

export function VehicleCard({ vehicle, selectedVehicles, handleReload, handleSelected }:Props) {
  const selected = useMemo(() => !!selectedVehicles.find(v => v.id===vehicle.id), [vehicle, selectedVehicles]);

  const handleBlacklist = useCallback(() => {
    if (window.confirm(`確定要將${vehicle.id}列入黑名單嗎？`)) {
      postVehicle(vehicle.id, { blacklisted: true }).then(() => {
        handleReload();
      });
    }
  }, [vehicle, handleReload]);

  const handleUnblacklist = useCallback(() => {
    if (window.confirm(`確定要將${vehicle.id}從黑名單中移除嗎？`)) {
      postVehicle(vehicle.id, { blacklisted: false }).then(() => {
        handleReload();
      });
    }
  }, [vehicle, handleReload]);
  
  return <Card variant={"outlined"} style={{ marginTop: 5, marginBottom: 5, backgroundColor: selected ? "#ccffcc" : undefined, borderColor: "black" }}>
    <Box style={{ paddingTop: 5, paddingLeft: 10, paddingBottom: 5, backgroundColor: vehicle.blacklisted ? "#ffcccc" : "#ccffff" }}>
      <FormGroup>
        <FormControlLabel
          style={{ fontWeight: "bold", fontSize: "large" }}
          control={<Checkbox checked={selected} />}
          label={vehicle.id}
          onChange={handleSelected}
        />
      </FormGroup>
    </Box>
    <Box style={{ padding: 10 }}>
      <Box style={{ marginBottom: 5 }}>
        <Typography style={{ fontSize: "small" }}>車輛類型</Typography>
        <Typography>{vehicle.vehicleType?.name}</Typography>
      </Box>
      <Box style={{ marginBottom: 5 }}>
        <Typography style={{ fontSize: "small" }}>司機電話</Typography>
        <Typography>{vehicle.driverPhone}</Typography>
      </Box>
      <Box style={{ marginBottom: 5 }}>
        <Typography style={{ fontSize: "small" }}>已列入黑名單</Typography>
        <Typography>{vehicle.blacklisted ? "✓" : "-"}</Typography>
      </Box>
    </Box>
    <Stack direction={"row"}>
      {vehicle.blacklisted ?
        <Button fullWidth color={"success"} onClick={handleUnblacklist}>從黑名單中移除</Button>:
        <Button fullWidth color={"error"} onClick={handleBlacklist}>列入黑名單</Button>
      }
      <Button fullWidth variant={"contained"}>發送訊息</Button>
    </Stack>
  </Card>
}