import { SendMessageDialog } from "@components/SendMessageDialog";
import { Box, Button, Stack, Typography } from "@mui/material";
import { postVehicle } from "@utils/api/vehicle";
import { Vehicle } from "@_types/Vehicle";
import _ from "lodash";
import React, { useCallback, useState } from "react";

type Props = {
  selectedVehicles: Vehicle[]
  setSelectedVehicles: React.Dispatch<React.SetStateAction<Vehicle[]>>
  handleReload: () => any
}

export function MultipleSelectToolbar({ selectedVehicles, setSelectedVehicles, handleReload }: Props) {
  const [openSendMessageDialog, setOpenSendMessageDialog] = useState(false);
  
  const handleBlacklist = useCallback(() => {
    if (window.confirm(`確定將${selectedVehicles.length}個司機列入黑名單？`)) {
      Promise.allSettled(selectedVehicles.map(vehicle => postVehicle(vehicle.id, { blacklisted: true }))).then(response => {
        alert(`成功將${response.filter(x => x.status==="fulfilled").length}個司機列入黑名單`);
        handleReload()
      });
    }
  }, [selectedVehicles, handleReload]);

  const handleUnblacklist = useCallback(() => {
    if (window.confirm(`確定將${selectedVehicles.length}個司機從黑名單中移除？`)) {
      Promise.allSettled(selectedVehicles.map(vehicle => postVehicle(vehicle.id, { blacklisted: false }))).then(response => {
        alert(`成功將${response.filter(x => x.status==="fulfilled").length}個司機從黑名單中移除`);
        handleReload()
      });
    }
  }, [selectedVehicles, handleReload]);

  const handleSendMessage = useCallback(() => {
    setOpenSendMessageDialog(true);
  }, []);

  const handleCancelSelection = useCallback(() => {
    setSelectedVehicles([]);
  }, [setSelectedVehicles]);
  
  return <Box style={{ position: "sticky", bottom: 0, backgroundColor: "white", width: "100%", boxShadow: "0 -5px 5px grey", paddingBottom: "1em" }}>
    <Typography style={{ textAlign: "right", marginTop: 10 }}>已選擇 {selectedVehicles.length} 筆記錄</Typography>
    <Stack direction={"row"}>
      <Button fullWidth color={"error"} onClick={handleBlacklist}>列入黑名單</Button>
      <Button fullWidth color={"success"} onClick={handleUnblacklist}>從黑名單中移除</Button>
      <Button fullWidth onClick={handleSendMessage}>發送訊息</Button>
      <Button fullWidth color={"error"} onClick={handleCancelSelection}>取消選取</Button>
    </Stack>
    <SendMessageDialog open={openSendMessageDialog} handleClose={() => setOpenSendMessageDialog(false)} driverPhones={_.chain(selectedVehicles).map("driverPhone").compact().uniq().value()}/>
  </Box>
}