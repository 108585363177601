import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { updateSearchParams } from "@utils/updateSearchParams";

type Props = {
  handleSearch: () => void
}

export function Filter(props:Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [q, setQ] = useState("");
  const [blacklisted, setBlacklisted] = useState("");

  const handleSearch = useCallback(() => {
    props.handleSearch();
  }, [props]);

  useEffect(() => {
    setQ(searchParams.get("q") || "");
    setBlacklisted(searchParams.get("blacklisted") || "");
  }, [searchParams]);

  return <>
    <Grid container>
      <Grid item sm={9}>
        <TextField
          fullWidth
          placeholder={"搜尋"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={q}
          onChange={(e) => updateSearchParams(searchParams, setSearchParams, { q: e.target.value })}
        />
      </Grid>
      <Grid item sm={3}>
        <FormControl fullWidth>
          <InputLabel>黑名單</InputLabel>
          <Select
            value={blacklisted || ""}
            onChange={(e) => updateSearchParams(searchParams, setSearchParams, { blacklisted: e.target.value })}
          >
            <MenuItem value="">所有</MenuItem>
            <MenuItem value="true">黑名單</MenuItem>
            <MenuItem value="false">不在黑名單</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>

    <Button fullWidth style={{ marginTop: 10 }} onClick={handleSearch}>搜尋</Button>
  </>
}