import { Box, CircularProgress, Pagination, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Filter } from './Filter';
import { useSearchParams } from 'react-router-dom';
import { VehicleCard } from './VehicleCard';
import { Vehicle } from '@_types/Vehicle';
import { fetchVehicles } from '@utils/api/vehicle';
import { MultipleSelectToolbar } from './MultipleSelectToolbar';

export function VehicleList() {
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [count, setCount] = useState<number|undefined>(undefined);

  const [selectedVehicles, setSelectedVehicles] = useState<Vehicle[]>([]);

  const handleSearch = useCallback(() => {
    setLoading(true);
    fetchVehicles({
      q: searchParams.get("q") || "",
      vehicleType: searchParams.get("vehicleType") || "",
      blacklisted: searchParams.get("blacklisted") || "",
    }, { page, size })
      .then(response => {
        setCount(response.count);
        setVehicles(response.rows);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, page, size]);

  const handleSelected = useCallback((vehicle: Vehicle) => {
    if (selectedVehicles.find(x => x.id === vehicle.id)) {
      setSelectedVehicles(old => old.filter(x => x.id !== vehicle.id));
    } else {
      setSelectedVehicles(old => [...old, vehicle]);
    }
  }, [selectedVehicles]);

  useEffect(handleSearch, [handleSearch]);

  useEffect(() => {
    setPage(1);
  }, [searchParams]);

  return <>
    <Typography variant={"h4"}>車輛列表</Typography>
    <Filter handleSearch={handleSearch} />
    
    <Box style={{ marginTop: 10, width: "100%", alignItems: "center" }}>
      <Pagination
        page={page}
        count={Math.ceil((count||0)/size)}
        onChange={(_, x) => setPage(x)}
        style={{ textAlign: "center" }}
      />
      {(count !== undefined) && <Typography style={{ textAlign: "right", marginTop: 10 }}>共找到 {count} 筆記錄</Typography>}
    </Box>

    {loading && <Box style={{ margin: 10, textAlign: "center", width: "100%" }}>
      <CircularProgress />
    </Box>}

    {!loading && <>
      {vehicles.map(vehicle => <VehicleCard key={vehicle.id} vehicle={vehicle} selectedVehicles={selectedVehicles} handleReload={handleSearch} handleSelected={() => {handleSelected(vehicle)}}/>)}
      {!!selectedVehicles.length && <MultipleSelectToolbar selectedVehicles={selectedVehicles} setSelectedVehicles={setSelectedVehicles} handleReload={handleSearch}/>}
    </>}
  </>;
}
