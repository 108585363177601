import React, { useCallback, useEffect, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { Alert, Box, Button, CircularProgress, IconButton, List, ListItem, ListItemText, Paper, Snackbar, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { filesize } from 'filesize';
import { postImportTask } from '@utils/api/importTask';
import axios from 'axios';

export function BatchImportVehiclePass() {
  const [files, setFiles] = useState<File[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((file:FileList) => {
    setFiles((value) => [...value, ...[...file].filter(f => !files.map(x => x.name).includes(f.name))]);
  }, [files]);

  const handleUpload = useCallback(() => {
    // const formData = new FormData();
    // for (const file of files) {
    //   formData.append('file', file);
    // }

    // postVehiclePassBatchImport(formData)
    // .then(() => {
    //   setOpen(true);
    //   setFiles([]);
    // })
    // .catch(error => {
    //   alert(`未能成功匯入許可證 (${error.response.data.message})`)
    // })
    // .finally(() => setLoading(false));
    setLoading(true);
    postImportTask(files.map(file => file.name))
    .then(async ({id, signedUrls}) => {
      await Promise.all(signedUrls.map(async (signedUrl, i) => {
        return axios.put(signedUrl, await files[i].arrayBuffer())
      }));
      return id;
    })
    // .then((id) => {
    //   return postImportTaskDone(id)
    // })
    .then(() => {
      setOpen(true);
      setFiles([]);
    })
    .catch(error => {
      alert(`未能成功匯入許可證 (${error.response.data.message || error.message})`)
    })
    .finally(() => {
      setLoading(false);
    });
  }, [files]);

  useEffect(() => {
    console.log(files);
  }, [files]);

  return <>
    <Typography variant={"h4"}>匯入許可證</Typography>
    <FileUploader
      handleChange={handleChange}
      multiple
      name="file"
      types={["PDF", "ZIP"]}
      maxSize={1000}
      label={"按此選擇檔案上載"}
      hoverTitle={"拖曳檔案至此"}
    />
    <Paper>
      <Typography>檔案列表</Typography>
      <List>
        {[...files].map((file, i) => <ListItem
          key={i}
          secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={() => setFiles(files.filter((_, j) => i !== j))}>
              <DeleteIcon />
            </IconButton>
          }
        >
          <ListItemText
            primary={file.name}
            secondary={filesize(file.size, { base: 2 }).toString()}
          />
        </ListItem>)}
      </List>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button fullWidth disabled={files.length === 0 || loading} onClick={handleUpload}>{loading?"上載中...":"上載"}</Button>
        {loading && <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />}
      </Box>
    </Paper>

    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={() => setOpen(false)}
    >
      <Alert severity={"success"}>已成功上載</Alert>
    </Snackbar>
  </>
}