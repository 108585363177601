import { fetchFinishedCount } from "@utils/api/report";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Box, Button, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DataGrid } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import "./index.scss";

type Row = {date:string, count:number}

function getRowColour(row:Row) {
  if (row.count === 0) {
    return "no-data";
  }
  return "";
}

export function ReportsFinishedCount() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState<moment.Moment|null>(searchParams.get("start") ? moment(searchParams.get("start")) : null);
  const [end, setEnd] = useState<moment.Moment|null>(searchParams.get("end") ? moment(searchParams.get("end")) : null);
  const [data, setData] = useState<Row[]>([]);

  const handleLoad = useCallback(() => {
    const start = searchParams.get("start");
    const end = searchParams.get("end");
    if (start && end) {
      setLoading(true);
      fetchFinishedCount(start, end)
      .then(response => {
        setData(response.map(x => ({
          id: x.date,
          ...x
        })));
      })
      .finally(() => {
        setLoading(false);
      })
    }
  }, [searchParams]);

  const handleSearch = useCallback(() => {
    if (start && end) {
      setSearchParams({
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
      });
    }
  }, [start, end]);

  useEffect(handleLoad, [handleLoad]);

  useEffect(() => {
    if (start && !end?.isValid()) {
      setEnd(start)
    }
  }, [start]);

  return <Box style={{ marginTop: 10 }}>
    <Stack direction="row">
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true
          }
        }}
        label="開始日期"
        format="YYYY-MM-DD"
        maxDate={end || undefined}
        value={start}
        onChange={setStart}
      />
      <DatePicker
        slotProps={{
          textField: {
            fullWidth: true
          }
        }}
        label="結束日期"
        format="YYYY-MM-DD"
        minDate={start || undefined}
        value={end}
        onChange={setEnd}
      />
    </Stack>
    <Button fullWidth onClick={handleSearch}>搜尋</Button>
    <Box style={{ height: "80vh"}}>
      <DataGrid
        rows={data}
        loading={loading}
        disableColumnMenu
        columns={[
          { field: "date", headerName: "日期", width: 150 },
          { field: "count", headerName: "數目" },
        ]}
        getRowClassName={({row}) => {
          return getRowColour(row);
        }}
        rowHeight={30}
      />
    </Box>
  </Box>
}