import { GridSortModel } from "@mui/x-data-grid";
import { api } from ".";

export async function postImportTask(files:string[]) {
  const response = await api.post<{id:string, signedUrls:string[]}>("/importTask", { files });

  return response.data;
}

export async function postImportTaskDone(importTaskId:string) {
  const response = await api.post("/importTask/done", { importTaskId });

  return response.data;
}

export async function importTasksGet(params:any, page:number, size:number, sortModel:GridSortModel) {
  const response = await api.get("/importTask", {
    params: {
      ...params,
      page,
      size,
      order: sortModel.map(({field, sort}) => `${field}|${sort}`).join(",")
    }
  });

  return response.data;

}

export async function importTaskGet(id:string) {
  const response = await api.get("/importTask", { params: { id }});

  return response.data;
}