import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Event } from "@_types/Event";
import { fetchEvents } from "@utils/api/event";
import { updateSearchParams } from "@utils/updateSearchParams";
import { VehiclePassFilter } from "@_types/VehiclePass";
import moment from "moment";
import { fetchTimeslotsByEventId } from "@utils/api/timeslot";
import { fetchVehiclePassesCount } from "@utils/api/vehiclePass";

type Props = {
  setFilter: React.Dispatch<React.SetStateAction<VehiclePassFilter | undefined>>
}

export function Filter({ setFilter }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [events, setEvents] = useState<Event[]>([]);
  const [timeslots, setTimeslots] = useState<Timeslot[]>([]);

  const [order, setOrder] = useState("event-and-timeslot");

  const [q, setQ] = useState("");
  const [event, setEvent] = useState("");
  const [timeslot, setTimeslot] = useState("");
  const [status, setStatus] = useState<string[]>([]);
  const [mode, setMode] = useState("");
  const [phase, setPhase] = useState("");
  const [level, setLevel] = useState("");
  const [loadingCount, setLoadingCount] = useState(false);
  const [count, setCount] = useState<{ [key: string]: number }>({});

  const handleGetCount = useCallback(() => {
    setLoadingCount(true);
    fetchVehiclePassesCount({
      q,
      order,
      event,
      mode,
      phase,
      level,
      timeslot
    })
      .then(data => {
        setCount(data);
      })
      .finally(() => {
        setLoadingCount(false);
      })
  }, [q, event, timeslot, order, mode, phase, level]);

  const handleSearch = useCallback(() => {
    setFilter({
      q,
      order,
      event,
      mode,
      phase,
      level,
      timeslot,
      status
    });
  }, [setFilter, q, event, timeslot, status, order, mode, phase, level]);

  useEffect(handleGetCount, [handleGetCount]);

  const handleChangeStatus = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let newStatus = [...status];
    if (e.target.checked) {
      newStatus.push(e.target.name)
    } else {
      newStatus = newStatus.filter(x => x !== e.target.name);
    }

    updateSearchParams(searchParams, setSearchParams, { status: newStatus.filter(x => x).join(",") })
  }, [status, searchParams, setSearchParams]);

  const showStatusCount = useCallback((key: string) => {
    if (loadingCount) {
      return "-";
    } else {
      return count[key] || 0;
    }
  }, [loadingCount, count]);

  useEffect(() => {
    fetchEvents()
      .then((response) => {
        setEvents(response)
      });
  }, []);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    if (event) {
      fetchTimeslotsByEventId(event).then(response => setTimeslots(response));
    }
  }, [event]);

  useEffect(() => {
    setQ(searchParams.get("q") || "");
    setEvent(searchParams.get("event") || "");
    setTimeslot(searchParams.get("timeslot") || "");
    setOrder(searchParams.get("order") || "");
    setPhase(searchParams.get("phase") || "")
    setLevel(searchParams.get("level") || "")
    setMode(searchParams.get("mode") || "")
    setStatus(searchParams.get("status")?.split(",") || []);
  }, [searchParams]);

  return <Box>
    <TextField
      fullWidth
      placeholder={"搜尋"}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={q}
      onChange={(e) => updateSearchParams(searchParams, setSearchParams, { q: e.target.value })}
    />
    <Stack direction={"row"} spacing={1} style={{ marginTop: "1em" }}>
      <FormControl fullWidth>
        <InputLabel>活動</InputLabel>
        <Select
          value={event}
          onChange={(e) => updateSearchParams(searchParams, setSearchParams, {
            event: e.target.value,
            ...(!e.target.value && { timeslot: "" })
          })}
        >
          <MenuItem value="">所有</MenuItem>
          {events.map(event => <MenuItem key={event.id} value={event.id}>({event.id}) {event.name}</MenuItem>)}
        </Select>
      </FormControl>

      {event && <FormControl fullWidth>
        <InputLabel>時段</InputLabel>
        <Select
          disabled={!event}
          value={timeslot}
          onChange={(e) => updateSearchParams(searchParams, setSearchParams, { timeslot: e.target.value })}
        >
          <MenuItem value="">所有</MenuItem>
          {timeslots.map(timeslot => <MenuItem key={timeslot.id} value={timeslot.id}>({timeslot.eventId}) {moment(timeslot.startTime).format("DD/MM HH:mm")} - {moment(timeslot.endTime).format("DD/MM HH:mm")}</MenuItem>)}
        </Select>
      </FormControl>}

      <FormControl style={{ width: "50%" }}>
        <InputLabel>期數</InputLabel>
        <Select
          value={phase}
          onChange={(e) => updateSearchParams(searchParams, setSearchParams, { phase: e.target.value })}
        >
          <MenuItem value="">所有</MenuItem>
          <MenuItem value="1">1期</MenuItem>
          <MenuItem value="2">2期</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ width: "50%" }}>
        <InputLabel>樓層</InputLabel>
        <Select
          value={level}
          onChange={(e) => updateSearchParams(searchParams, setSearchParams, { level: e.target.value })}
        >
          <MenuItem value="">所有</MenuItem>
          <MenuItem value="L1">L1</MenuItem>
          <MenuItem value="L2">L2</MenuItem>
          <MenuItem value="L3">L3</MenuItem>
          <MenuItem value="L4">L4</MenuItem>
          <MenuItem value="L5">L5</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ width: "50%" }}>
        <InputLabel>模式</InputLabel>
        <Select
          value={mode}
          onChange={(e) => updateSearchParams(searchParams, setSearchParams, { mode: e.target.value })}
        >
          <MenuItem value="">所有</MenuItem>
          <MenuItem value="TY">TY</MenuItem>
          <MenuItem value="GF">GF</MenuItem>
          <MenuItem value="FF">FF</MenuItem>
          <MenuItem value="FFC">FFC</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <Stack direction="row">
          <FormGroup style={{ width: "100%" }}>
            <FormControlLabel
              control={<Checkbox name={"BINDED"} checked={status.includes("BINDED")} onChange={handleChangeStatus} />}
              label={`已登記 (${showStatusCount("BINDED")})`}
            />
            <FormControlLabel
              control={<Checkbox name={"QUEUED"} checked={status.includes("QUEUED")} onChange={handleChangeStatus} />}
              label={`已取籌（未放行） (${showStatusCount("QUEUED")})`}
            />
            <FormControlLabel
              control={<Checkbox name={"RELEASED"} checked={status.includes("RELEASED")} onChange={handleChangeStatus} />}
              label={`已放行 (${showStatusCount("RELEASED")})`}
            />
          </FormGroup>
          <FormGroup style={{ width: "100%" }}>
            <FormControlLabel
              control={<Checkbox name={"CHECKED_IN"} checked={status.includes("CHECKED_IN")} onChange={handleChangeStatus} />}
              label={`已打卡 (${showStatusCount("CHECKED_IN")})`}
            />
            <FormControlLabel
              control={<Checkbox name={"FINISHED"} checked={status.includes("FINISHED")} onChange={handleChangeStatus} />}
              label={`已到達會展 (${showStatusCount("FINISHED")})`}
            />
          </FormGroup>
        </Stack>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel>排序</InputLabel>
        <Select
          value={order}
          onChange={(e) => updateSearchParams(searchParams, setSearchParams, { order: e.target.value })}
        >
          <MenuItem value="event-and-timeslot">活動名稱及時段</MenuItem>
          <MenuItem value="license">車牌</MenuItem>
          <MenuItem value="finish-time">到達會展時間</MenuItem>
          <MenuItem value="queue-pos">籌號</MenuItem>
        </Select>
      </FormControl>
    </Stack>

    {/* <Button fullWidth style={{ marginTop: 10 }} onClick={handleSearch}>搜尋</Button> */}
  </Box>
}