import { Box, Button, Card, Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material";
import { postDriver } from "@utils/api/driver";
import { Driver } from "@_types/Driver";
import { useCallback, useMemo, useState } from "react";
import { SendMessageDialog } from "@components/SendMessageDialog";

type Props = {
  driver: Driver
  selectedDrivers: Driver[]
  handleReload: () => any
  handleSelected: () => any
}

export function DriverCard({ driver, selectedDrivers, handleReload, handleSelected }:Props) {
  const [openSendMessageDialog, setOpenSendMessageDialog] = useState(false);

  const selected = useMemo(() => !!selectedDrivers.find(d => d.phone===driver.phone), [driver, selectedDrivers]);

  const handleBlacklist = useCallback(() => {
    if (window.confirm(`確定要將${driver.phone}列入黑名單嗎？`)) {
      postDriver(driver.phone, true).then(() => {
        handleReload();
      });
    }
  }, [driver, handleReload]);

  const handleUnblacklist = useCallback(() => {
    if (window.confirm(`確定要將${driver.phone}從黑名單中移除嗎？`)) {
      postDriver(driver.phone, false).then(() => {
        handleReload();
      });
    }
  }, [driver, handleReload]);
  
  return <Card variant={"outlined"} style={{ marginTop: 5, marginBottom: 5, backgroundColor: selected ? "#ccffcc" : undefined, borderColor: "black" }}>
    <Box style={{ paddingTop: 5, paddingLeft: 10, paddingBottom: 5, backgroundColor: driver.blacklisted ? "#ffcccc" : "#ccffff" }}>
      <FormGroup>
        <FormControlLabel
          style={{ fontWeight: "bold", fontSize: "large" }}
          control={<Checkbox checked={selected} />}
          label={driver.phone}
          onChange={handleSelected}
        />
      </FormGroup>
    </Box>
    <Box style={{ padding: 10 }}>
    </Box>
    <Stack direction={"row"}>
      {driver.blacklisted ?
        <Button fullWidth color={"success"} onClick={handleUnblacklist}>解除黑名單</Button>:
        <Button fullWidth color={"error"} onClick={handleBlacklist}>列入黑名單</Button>
      }
      <Button fullWidth variant={"contained"} onClick={() => setOpenSendMessageDialog(true)}>發送訊息</Button>
    </Stack>
    <SendMessageDialog open={openSendMessageDialog} handleClose={() => setOpenSendMessageDialog(false)} driverPhones={[driver.phone]}/>
  </Card>
}